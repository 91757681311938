<div class="container p-2 p-md-4">
  <div class="header-section">
    <div class="d-flex">
      <img
      [src]="'/assets/img/mybadge.png'"
      [alt]="'Brand.BrandTitle' | translate"
      class="g-0 me-2 page-title-icon"
      />
      <h3 translate class="col gx-2">Badge.BadgeTitle</h3>
    </div>
  </div>
      <div class="row">
        <div class="row mb-3 mt-1">
          <div class="col-lg-10 col-sm-10">
            <p translate>Badge.BadgeTitleNotes</p>
          </div>
        </div>
        <div
        class="row"
        infiniteScroll
        (scrolled)="onScroll()"
        [infiniteScrollDistance]="0.5"
        [infiniteScrollThrottle]="300"
      >
        <div
          class="d-flex align-items-center justify-content-between"
          style="overflow-x: auto"
        >
          <ul
            ngbNav
            #nav="ngbNav"
            class="nav-tabs sticky-nav"
            style="min-width: 500px; min-height: 50px;"
            destroyOnHide="true"
            [(activeId)]="activeTab"
            (activeIdChange)="onTabChange(activeTab)"
          >
            <li [ngbNavItem]="1" class="me-lg-5">
              <a ngbNavLink translate>Badge.UploadedByOrganization</a>
              <ng-template ngbNavContent>
                <app-manage-badge-list-table
                  [badges]="badges"
                  (onBadgeDelete)="deleteBadge($event)"
                ></app-manage-badge-list-table>
              </ng-template>
            </li>
            <li [ngbNavItem]="2" class="me-lg-5">
              <a ngbNavLink translate>Badge.UploadedByMe</a>
              <ng-template ngbNavContent>
                <app-manage-badge-list-table
                  [badges]="badges"
                  (onBadgeDelete)="deleteBadge($event)"
                >
                </app-manage-badge-list-table>
              </ng-template>
            </li>
          </ul>

          <button
            class="btn btn-primary d-none d-md-block"
            type="button"
            (click)="addBadge()"
            translate
          >
            Badge.AddNewBadge
          </button>
         
        </div>
        <div class="mt-2">
          <hr />
        </div>

        <div [ngbNavOutlet]="nav"></div>
      </div>
    </div>
  </div>
