import { Base64File } from './Base64File';
import { UserForManage } from './UserForManage';

export class CreateSolutionListing {
  solutionListingId?: string;
  organizationId?: string;
  name?: string;
  applyTo?: string;
  publishTypeId?: string;

  validFrom?: string;
  validTo?: string;

  primaryImageUrl?: string;
  primaryImageAsBase64?: Base64File;

  description?: string;
  languages?: string[];
  locations?: string;
  tags?: string;
  links?: string[];
  getDescription?: string;

  images?: string[];
  imagesAsBase64?: Base64File[];

  embeddedVideoUrl?: string;
  termTypeId: string | null = null;
  termsUrl?: string;

  active?: boolean;

  createdOn?: string;
  createdByUser?: UserForManage;
}
