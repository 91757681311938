<div class="body flex-grow-1 container px-3 text-start">
  <div class="body flex-grow-1 text-start"></div>
  <div class="container-lg">
    <div class="row hp-1">
      <div>
        <h2 translate class="wp-block-heading">FindSolutions.Title</h2>
        <div class="row"></div>
        <div class="row mb-2 d-inline-flex col-md-12">
          <div class="entry-content mb-4">
            
            <!-- Welcome message with link -->
            <div class="welcome">
              {{ 'FindSolutions.WelcomeMessage' | translate }}
              <a href="https://mva.net/solutions/" target="_blank">{{ 'FindSolutions.AddHere' | translate }}</a>
            </div>

            <!-- Horizontal line after the welcome message -->
            <hr class="divider-line">

            <table class="solution-table">
              <tr>
                <th></th>
                <th>{{ 'FindSolutions.Solution' | translate }}</th>
                <th>{{ 'FindSolutions.Provider' | translate }}</th>
                <th>{{ 'FindSolutions.Tags' | translate }}</th>
              </tr>

              <!-- Loop through solutionProviders array -->
              <ng-container *ngFor="let provider of solutionProviders; let i = index">
                <tr>
                  <td class="expand-button" (click)="toggleExpand(i)">
                    <span [class.rotated]="expandedRow === i">▶</span>
                  </td>
                  <td class="solution-name">
                    <img [src]="provider.logo" alt="{{ 'FindSolutions.Providers.Provider' + (i + 1) + '.Name' | translate }} logo" style="width: 50px; height: auto;">
                    {{ 'FindSolutions.Providers.Provider' + (i + 1) + '.Name' | translate }}
                  </td>
                  <td>{{ 'FindSolutions.Providers.Provider' + (i + 1) + '.Company' | translate }}</td>
                  <td>
                    <!-- Wrap each tag in a span element with a class for styling -->
                    <span class="tag" *ngFor="let tag of ('FindSolutions.Providers.Provider' + (i + 1) + '.Tags' | translate).split('|')">
                      {{ tag }}
                    </span>
                  </td>
                </tr>

                <!-- Expanded row content -->
                <tr *ngIf="expandedRow === i" class="expanded-content">
                  <td colspan="4">
                    <p [innerHTML]="'FindSolutions.Providers.Provider' + (i + 1) + '.Description' | translate"></p>
                    <p [innerHTML]="'FindSolutions.Providers.Provider' + (i + 1) + '.ExtendedDescription' | translate"></p>

                    <!-- ValidFromTo field: Show only when available -->
                    <p *ngIf="'FindSolutions.Providers.Provider' + (i + 1) + '.ValidFromTo' | translate">
                      <strong>{{ 'FindSolutions.Providers.Provider' + (i + 1) + '.ValidFromTo' | translate }}</strong>
                    </p>

                    <!-- LanguagesSupported -->
                    <p *ngIf="'FindSolutions.Providers.Provider' + (i + 1) + '.Languages' | translate">
                      <strong>{{ 'FindSolutions.LanguagesSupported' | translate }}:&nbsp;</strong>{{ 'FindSolutions.Providers.Provider' + (i + 1) + '.Languages' | translate }}
                    </p>

                    <!-- LocationsSupported -->
                    <p *ngIf="'FindSolutions.Providers.Provider' + (i + 1) + '.Locations' | translate">
                      <strong>{{ 'FindSolutions.LocationsSupported' | translate }}:&nbsp;</strong>{{ 'FindSolutions.Providers.Provider' + (i + 1) + '.Locations' | translate }}
                    </p>

                    <!-- Links -->
                    <p *ngIf="provider.link">
                      <strong>{{ 'FindSolutions.Links' | translate }}:&nbsp;</strong>
                      <a [href]="provider.link" target="_blank">{{ provider.link }}</a>
                    </p>

                    <!-- Contact -->
                    <p *ngIf="'FindSolutions.Providers.Provider' + (i + 1) + '.Contact' | translate">
                      <strong>{{ 'FindSolutions.Contact' | translate }}:&nbsp;</strong>{{ 'FindSolutions.Providers.Provider' + (i + 1) + '.Contact' | translate }}
                    </p>

                  </td>
                </tr>
              </ng-container>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .expand-button span {
    display: inline-block;
    transition: transform 0.3s ease;
  }

  .expand-button span.rotated {
    transform: rotate(90deg);
  }
</style>