<div class="container p-2 p-md-4 solutions-listing-container">
  <!-- Title and Subtitle -->
  <div class="row mb-1">
    <div class="d-flex mb-2">
      <img
        src="../../../../assets/icons/find-solutions.png"
        style="box-sizing: content-box; width: 20px; height: 20px"
        class="g-0 me-2"
      />
      <h1 translate class="col gx-2">Solutions.Title</h1>
    </div>

    <h2 class="mt-1" translate>Solutions.Subtitle</h2>
  </div>

  <!-- Sort and filter -->
  <form [formGroup]="form">
    <div class="row mb-3 mb-lg-5">
      <!-- Sort by Dropdown -->
      <div
        class="col-12 col-lg-4 column-gap-2 column-gap-lg-0 align-self-center"
      >
        <select
          class="form-select my-auto"
          formControlName="sortBy"
          aria-label="Sort select"
        >
          <option
            selected
            [value]="opt.value"
            *ngFor="let opt of sortOptions"
            translate
          >
            {{ opt.label }}
          </option>
        </select>
      </div>

      <!-- Spacer -->
      <div class="col-0 col-lg"></div>

      <!-- All/Favorites toggle -->
      <div
        class="btn-group col-12 col-lg-3 gy-2 gy-lg-0 mx-lg-3"
        role="group"
        aria-label="Basic radio toggle button group"
        id="solution-type-toggle"
      >
        <input
          type="radio"
          class="btn-check"
          id="solutions-type-all"
          autocomplete="off"
          formControlName="fav"
          value="0"
        />
        <label class="btn btn-primary" for="solutions-type-all" translate>
          Solutions.SortAndFilter.AllButtonText
        </label>

        <input
          type="radio"
          class="btn-check"
          id="solutions-type-favorites"
          autocomplete="off"
          formControlName="fav"
          value="1"
        />
        <label class="btn btn-primary" for="solutions-type-favorites" translate>
          Solutions.SortAndFilter.FavoritesButtonText
        </label>
      </div>

      <!-- Filters button -->
      <div class="col-12 col-lg-3 col-xl-2 gy-2 gy-lg-0">
        <button
          class="btn btn-primary w-100 h-100"
          (click)="filterDrawer.open()"
        >
          {{ "Solutions.SortAndFilter.FiltersButtonText" | translate }} ({{
            totalFilterCount
          }})
        </button>
      </div>
    </div>
  </form>

  <hr class="mb-3" />

  <div class="d-flex mt-4 mb-3 gap-3">
    <a
      class="nav-tab"
      routerLink="/find-solutions/solutions"
      routerLinkActive="active"
      translate
      >Solutions.TableTitle</a
    >
    <a
      class="nav-tab mr-auto"
      routerLink="/find-solutions/badges"
      routerLinkActive="active"
      translate
      >Solutions.FindBadges</a
    >
    <p *ngIf="totalItemCount !== null" class="align-self-center mb-0">
      {{ totalItemCount }} items found
    </p>
  </div>

  <!-- Solutions table -->
  <router-outlet></router-outlet>

  <!-- Filter drawer -->
  <app-checkbox-filter-drawer
    #filterDrawer
    [panels]="filterPanels"
    (onCheckCountUpdate)="totalFilterCount = $event"
    (onClose)="onFilterDrawerUpdate($event)"
  ></app-checkbox-filter-drawer>
</div>
