<div class="table-responsive mt-4">
  <table class="table">
    <thead class="table-header-custom">
      <tr>
        <th></th>
        <th translate>Badge.TableName</th>
        <th translate>Badge.TableCreated</th>
        <th translate>Badge.TableUploaded</th>
        <th translate>Badge.TableStatus</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr class="custom-row" *ngFor="let badge of badges" (click)="goToDetails(badge.badgeId!)">
        <td align="center">
          <img [src]="badge.primaryImageUrl 
    ?? '/assets/img/default-solution.png'" [id]="badge.badgeId" [title]="'Common.ViewDetails' | translate"
            class="round-image border border-secondary-subtle me-3  d-md-inline" />
        </td>
        <td class="pt-4">
          
            {{ badge.name }}
         
        </td>
        <td  class="pt-4">
         {{ badge.createdOn | date : "MMM d, y" }}
        </td>
        <td  class="pt-4">
          {{ badge.createdByUser?.firstName }}
            {{ badge.createdByUser?.lastName }}
        </td>
        <td class="pt-4">
          <active-status [active]="badge.active ?? false"></active-status>
        </td>
        <td class="pt-4">
          <!-- Three-dot Dropdown Icon -->
          <div ngbDropdown container="body">
            <button dropdown-toggle btn p-0 class="btn p-0 hide-arrow" ngbDropdownToggle aria-expanded="false"
              (click)="$event.stopPropagation()">
              <i class="bi bi-three-dots"></i>
            </button>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end custom-shadow p-0">
              <button ngbDropdownItem translate class="dropdown-item w-100 d-flex align-items-center"
                (click)="editBadge(badge.badgeId!)">

                <i class="bi bi-pencil-square me-2"></i>Common.Edit
              </button>
              <div class="dropdown-divider m-0"></div>
              <button ngbDropdownItem translate class="text-danger" (click)="deleteBadge(badge.badgeId!)">
                <i class="bi bi-trash me-2"></i>Common.Delete
              </button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>