<div class="body flex-grow-1 px-3">
  <form name="createBranForm" #brandForm="ngForm">
    <div class="container-lg hpr-1">
      <div class="row">
        <h4 class="fw-semibold pb-4" translate>Brand.EditBrandTitle</h4>
        <div class="col-lg-10 col-sm-10">
          <p translate>Brand.EditBrandTitleNotes</p>
        </div>
        <div class="actionbar mt-1">
          <div class="row">
            <div class="mb-3 row">
              <label
                for="brandName"
                class="col-sm-12 col-md-4 col-lg-2 col-form-label fw-semibold"
                translate
                >Brand.Name <span class="text-danger">*</span>
              </label>
              <div class="col-sm-12 col-md-8 col-lg-3">
                <input
                  class="form-control"
                  [(ngModel)]="brand.name"
                  name="brandName"
                  type="text"
                  id="brandName"
                  required
                  maxlength="100"
                  #brandName="ngModel"
                  [ngClass]="
                    (brandName.invalid &&
                      (brandName.dirty || brandName.touched)) ||
                    brandNameExistsError
                      ? 'invalid-input'
                      : ''
                  "
                />
                <span
                  class="text-danger"
                  *ngIf="
                    brandName.invalid &&
                    (brandName.dirty || brandName.touched) &&
                    brandName.errors?.required
                  "
                >
                  {{ "Brand.BrandNameRequired" | translate }}</span
                >
                <span class="text-danger" *ngIf="brandNameExistsError">
                  {{ "ErrorCodes.BRAND_NAME_ALREADY_EXISTS" | translate }}
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mb-3 row">
              <label
                for="brandLogo"
                class="col-sm-12 col-md-4 col-lg-2 col-form-label fw-semibold"
                translate
                >Brand.Logo</label
              >
              <div
                *ngIf="!this.logoUrl"
                class="col-sm-12 col-md-8 col-lg-3 d-inline-flex"
              >
                <div class="card brand-upload">
                  <div class="card-body mx-auto">
                    <label for="file-input">
                      <i
                        class="bi bi-plus-square-fill brand-upload-i"
                        title="Add brand logo"
                      ></i>
                    </label>
                    <input
                      id="file-input"
                      type="file"
                      accept="image/png,image/jpeg"
                      class="d-none"
                      name="brandLogo"
                      (change)="onlogoSelect($event)"
                    />
                  </div>
                </div>
              </div>
              <div *ngIf="this.logoUrl" class="col-sm-4 d-inline-flex">
                <div class="card brand-upload">
                  <input
                    id="file-input-preview"
                    #file
                    type="file"
                    accept="image/png,image/jpeg"
                    class="d-none"
                    (change)="onlogoSelect($event)"
                  />
                  <div class="card-body mx-auto" (click)="file.click()">
                    <img
                      [src]="logoUrl"
                      class="brand-upload-logo"
                      alt="Brand logo"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <div class="col-sm-1 col-form-label"></div>
              <div class="col-sm-4 d-inline-flex">
                <span class="text-danger" *ngFor="let error of logoFileErrors">
                  {{ error }}
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mb-3 row">
              <label
                for="brandName"
                class="col-sm-12 col-md-4 col-lg-2 col-form-label fw-semibold"
                translate
                >Brand.Exile</label
              >
              <div class="col-sm-12 col-md-8 col-lg-3">
                <div class="form-check form-switch pt-2">
                  <input
                    class="form-check-input"
                    name="isExile"
                    type="checkbox"
                    [(ngModel)]="brand.inExile"
                    [ngModelOptions]="{ standalone: true }"
                    role="switch"
                    id="excileOperation"
                  />
                  <label class="form-check-label" for="excileOperation">{{
                    brand.inExile ? "Yes" : "No"
                  }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="brandId">
            <div class="mb-3 row">
              <label
                for="brandName"
                class="col-sm-12 col-md-4 col-lg-2 col-form-label fw-semibold"
                translate
                >Brand.Properties</label
              >
              <div class="col-sm-12 col-md-8 col-lg-3 pt-2">
                <div *ngFor="let link of brand.properties" class="row">
                  <div class="col-sm-12 d-inline-flex">
                    <div class="pr-07">
                      <img [src]="getPropertyIcon(link.propertySubTypeId)" />
                    </div>
                    <p class="card-text pr-1">{{ link.propertyUrl }}</p>
                    <a class="btn-icon" role="button"
                      ><img
                        src="../../../../assets/icons/link-open.svg"
                        alt="Open property details"
                        (click)="
                          addEditProperty($event, addEditPropertycontent)
                        "
                        id="{{ link.propertyId }}"
                    /></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="mb-3 row">
                <label
                  class="col-sm-12 col-md-4 col-lg-2 col-form-label"
                ></label>
                <div class="col-lg-3 col-md-8 col-sm-8 d-inline-flex">
                  <button
                    class="btn btn-primary"
                    type="button"
                    (click)="addEditProperty($event, addEditPropertycontent)"
                    id="addPropertyButton"
                    translate
                  >
                    Brand.AddProperty
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="appContext.enableSolutions$ | async">
            <div class="mb-3 row">
              <label
                class="col-sm-12 col-md-4 col-lg-2 col-form-label fw-semibold"
                translate
                >Brand.Badges</label
              >
              <div class="col-sm-12 col-md-8 col-lg-3">
                <div *ngFor="let badge of brand.badges" class="badge-container">
                  <img [src]="badge.primaryImageUrl" alt="" />
                  <button type="button" (click)="onDeleteBadge(badge.badgeId)">
                    -
                  </button>
                </div>
                <div
                  *ngIf="brand.badges?.length === 0"
                >
                  <p translate class="mb-0">Brand.BadgesEmptyLine1</p>
                  <p translate>Brand.BadgesEmptyLine2</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row text-danger" *ngIf="vaidationErrors.length > 0">
            <span>{{ "ErrorCodes.BRAND_ERRORS" | translate }}</span>
            <ul>
              <li *ngFor="let error of vaidationErrors">{{ error }}</li>
            </ul>
          </div>
          <div class="row brand-footer">
            <div class="actionbar mt-3">
              <div class="col-sm-4 d-inline-flex gap-4">
                <button
                  type="button"
                  class="btn btn-secondary"
                  routerLink="/brands"
                  translate
                >
                  Brand.Cancel
                </button>
                <button
                  type="button"
                  class="btn btn-secondary mr-1"
                  [ngClass]="!brandId ? 'd-none' : ''"
                  translate
                  (click)="showDeleteDialog()"
                >
                  Property.Delete
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="onSave()"
                  translate
                >
                  Brand.Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<ng-template #addEditPropertycontent let-offcanvas>
  <app-add-edit-property
    [brandId]="brandId"
    [orgId]="orgaanisationId"
    [propertyId]="selectedPropertyId"
    (PropertySaved)="onPropertySaved($event)"
    (PropertySaveCancelled)="onPropertySaveCancelled()"
  ></app-add-edit-property>
</ng-template>

<div #deleteBrandModal class="modal fade show overlay" id="myModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" translate>Brand.Confirm</h4>
        <div
          type="button"
          class="btn-off"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="closeDialog()"
        ></div>
      </div>
      <div class="modal-body">
        <p class="pl-013 pt-4 modal-text fw-semibold" translate>
          Brand.DeleteWarning
        </p>
        <p class="p-3 modal-text" translate>Brand.DeleteMessage</p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeDialog()"
          translate
        >
          Organization.ConfirmNo
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="onDelete()"
          translate
        >
          Organization.ConfirmYes
        </button>
      </div>
    </div>
  </div>
</div>
