<div class="container badge-container" *ngIf="badge">
  <!-- BreadCrumbs -->
  <div class="mb-3">
    <a routerLink="/find-solutions/badges" translate>BadgeDetail.FindBadges</a>
    &#47; {{ badge.name.length > 25 ? (badge.name | slice:0:25) + '...' : badge.name }}
  </div>
  <!-- Title Row -->
  <div class="d-flex flex-wrap gap-3 justify-content-between title-row">
    <div class="d-flex align-items-center badge-title">
      <img
        [src]="
          badge.primaryImageUrl
            ? badge.primaryImageUrl
            : '../../../../assets/img/nologo.png'
        "
      />
      <h2 class="ms-2">{{ badge.name }}</h2>
    </div>
    <!-- Action Buttons -->
    <div class="d-flex gap-3 gap-sm-0">
      <button translate class="btn btn-primary" (click)="copyLink()">
        BadgeDetail.CopyLink
      </button>
      <button
        translate
        class="btn btn-primary mx-sm-3"
        (click)="toggleFavourite()"
      >
        <ng-container *ngIf="!badge.isFavourite">
          <img src="/assets/icons/favorite.png" />
          BadgeDetail.AddToFavorites
        </ng-container>

        <ng-container *ngIf="badge.isFavourite">
          <img src="/assets/icons/favorite-filled.png" />
          BadgeDetail.RemoveFromFavorites
        </ng-container>
      </button>
      <button
        translate
        class="btn btn-primary"
        [disabled]="!badge.active"
        (click)="showGetModal(badge.badgeId)"
      >
        BadgeDetail.ApplyForBadge
      </button>
    </div>
  </div>

  <div class="divider"></div>
  <div class="row" *ngIf="userLanguageId != badge.originalLanguageId">
    <p class="py-2 translation-text">
      {{ 'Common.TranslationDisclaimer' | translate }}
      <a (click)="ViewOriginal(badge)" translate>Common.OriginalText</a>
    </p>
    </div>
  <app-badge-detail-body 
   [badge]="badge"
   >
 </app-badge-detail-body>
</div>
