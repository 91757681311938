import { Component, OnDestroy, OnInit } from '@angular/core';
import { SolutionListing } from '../../../shared/models/SolutionListing';
import { SolutionListingService } from '../../../services/solution.listings.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  combineLatest,
  map,
  Observable,
  pipe,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NotificationService } from '../../../shared/services/notification.service';
import { AppContext } from 'src/app/shared/context/AppContext';
import { User } from 'src/app/shared/models/User';

@Component({
  selector: 'app-solutions-detail',
  templateUrl: './solutions-detail.component.html',
  styleUrls: ['./solutions-detail.component.css'],
})
export class SolutionsDetailComponent implements OnInit, OnDestroy {
  solution$?: Observable<SolutionListing>;
  solution?: SolutionListing;
  videoEmbedUrl?: SafeResourceUrl | null;
  userLanguageId?: string;
  _destroying$ = new Subject<void>();
  showOriginalText: boolean = false
  carouselOptions: OwlOptions = {
    items: 1,
    dots: true,
    loop: false,
  };

  constructor(
    private solutionListingService: SolutionListingService,
    private activatedRoute: ActivatedRoute,
    private domSanitizer: DomSanitizer,
    private notificationService: NotificationService,
    private appContext: AppContext,
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.appContext.user,
      this.activatedRoute.params
    ]).pipe(
      switchMap(([user, params]) =>(this.userLanguageId = user.languageId,
        this.solutionListingService.getSolutionListing(params.id, user.languageId))
      ),
      takeUntil(this._destroying$)
    ).subscribe((solution) => {
      this.solution = solution;
      this.videoEmbedUrl = this.sanitizeVideoUrl(solution.embeddedVideoUrl);
    });
    
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  sanitizeVideoUrl(url?: string) {
    if (!url) return null;

    if (url.startsWith('https://www.youtube.com/watch?v=')) {
      return this.domSanitizer.bypassSecurityTrustResourceUrl(
        url.replace('watch?v=', 'embed/')
      );
    }

    if (url.startsWith('https://www.youtube.com/embed/')) {
      return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }

    return null;
  }

  toggleFavourite() {
    if (!this.solution) return;

    this.solutionListingService
      .toggleFavourite(this.solution.solutionListingId)
      .subscribe((isFavourite) => {
        if (!this.solution) return;
        this.solution.isFavourite = isFavourite;
      });
  }

  copyLink() {
    window.navigator.clipboard
      .writeText(window.location.href)
      .then(() =>
        this.notificationService.showSuccessToast('Link copied to clipboard')
      );
  }
  hasValidLinks(): boolean {
    return !!this.solution?.links?.some(link => link && link.trim() !== '');
  }
  
  ViewOriginal(solution: SolutionListing) {
    this.showOriginalText = true
    this.solutionListingService
    .getSolutionListing(solution.solutionListingId)
    .subscribe((solution) => {
      this.solution = solution;
      this.videoEmbedUrl = this.sanitizeVideoUrl(solution.embeddedVideoUrl);
    })
  }
  ViewTranslated(solution: SolutionListing){
    this.showOriginalText = false
    this.solutionListingService
    .getSolutionListing(solution.solutionListingId,this.userLanguageId)
    .subscribe((solution) => {
      this.solution = solution;
      this.videoEmbedUrl = this.sanitizeVideoUrl(solution.embeddedVideoUrl);
    })
  }
}
