import { ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";

export function UrlValidator(control: AbstractControl) {
  const value = control.value;

  if (!value) {
    return null;
  }
  return !isValidUrl(value) ? { invalidUrl: true } : null;
}

function isUrlHttp(value: any): boolean {
  try {
    const newUrl = new URL(value);
    return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
  } catch (err) {
    return false;
  }
}
export function isValidUrl(url:any) : boolean {
  const urlRegex = /^(https?:\/\/)([\w\-]+\.)+[a-zA-Z]{2,}(\/[\w\-._~:/?#[\]@!$&'()*+,;=]*)?$/;
  return urlRegex.test(url);
}
