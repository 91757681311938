import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ScrollStateService } from 'src/app/shared/services/scrollstate.service';
import { FindMediaOrganization } from 'src/app/shared/models/OrganizationMedia';
import { UserMediaView } from 'src/app/shared/models/UserMediaView';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationTableComponent } from '../organization-table/organization-table.component';
import { BrandTableComponent } from '../brand-table/brand-table.component';
import { PropertyTableComponent } from '../property-table/property-table.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FindMediaBrand } from 'src/app/shared/models/FindMediaBrand';
import { FilterParams } from 'src/app/shared/models/FindMediaFilterParams';
import { FindMediaProperty } from 'src/app/shared/models/FindMediaProperty';
import { UsersavedviewService } from 'src/app/services/usersavedview.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
@Component({
  selector: 'app-findmedia',
  templateUrl: './findmedia.component.html',
  styleUrls: ['./findmedia.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class FindMediaComponent implements OnInit, AfterViewInit {
  @ViewChild('organizationTableComponent', { static: false })
  organizationTableComponent!: OrganizationTableComponent;
  @ViewChild('brandTableComponent', { static: false })
  brandTableComponent!: BrandTableComponent;
  @ViewChild('propertyTableComponent', { static: false })
  propertyTableComponent!: PropertyTableComponent;
  activeTab: number = 1;
  private readonly _destroying$ = new Subject<void>();
  searchForm!: FormGroup;
  UserViews: UserMediaView[] = [];
  filters!: FilterParams;
  pageNumber = 1;
  pageSize = 10;
  totalRecords = 0;
  isFavourite: boolean = false;
  params = {
    ...this.filters,
    pageNumber: this.pageNumber,
    pageSize: this.pageSize,
  };
  scrolldata: any[] = [];
  currentUrl: string = '';
  organizations: FindMediaOrganization[] = [];
  brands: FindMediaBrand[] = [];
  properties: FindMediaProperty[] = [];
  currentPageNumber: number = 1;
  constructor(
    private offcanvasService: NgbOffcanvas,
    private scrollStateService: ScrollStateService,
    private router: Router,
    private fb: FormBuilder,
    private userSavedViewService: UsersavedviewService,
    private gaService: GoogleAnalyticsService
  ) {}
  ngOnInit(): void {
    this.searchForm = this.fb.group({
      searchName: ['', [Validators.required]],
    });
    this.currentUrl = this.router.url;
  }
  ngAfterViewInit() {
    this.initializeViewData();
  }
  initializeViewData(): void {
    this.loadUserSavedViews();
  }
  allMedia(event: Event) {
    this.isFavourite = false;
    this.filters.isFavorite = this.isFavourite;
    const activeTabComponent = this.getActiveTabComponent();
    if (activeTabComponent) {
      activeTabComponent.pageNumber = 1;
      activeTabComponent.filters = this.filters;
    }
    this.loadDataForActiveTab();
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
    const scrollPosition = window.scrollY || document.documentElement.scrollTop;
    const data = this.getChangedRouteData();
    this.scrollStateService.saveState(
      this.currentUrl,
      scrollPosition,
      data,
      this.activeTab
    );
  }
  onScroll(): void {
    const activeTabComponent = this.getActiveTabComponent();
    if (activeTabComponent && activeTabComponent.pageNumber !== undefined) {
      activeTabComponent.pageNumber++; // Increment page number on scroll
      this.loadDataForActiveTab();
    }
  }
  openFilterDrawer(content: any) {
    this.offcanvasService.open(content, {
      ariaLabelledBy: 'offcanvas-basic-title',
      position: 'end',
      backdrop: 'static',
      animation: true,
      panelClass: 'custom-offcanvas-width',
    });
  }
  onFiltersChanged(filters: {
    organizationTypes: string[];
    propertySubTypes: string[];
    organizationAges: string[];
    noOfEmployee: string[];
    searchText: string;
    isFavorite: boolean;
  }): void {
    this.filters = filters;
    const activeTabComponent = this.getActiveTabComponent();
    if (activeTabComponent) {
      activeTabComponent.pageNumber = 1;
      activeTabComponent.filters = this.filters;
    }
    this.loadDataForActiveTab();
  }
  get filterCounter(): number {
    let counter = 0;
    if (this.filters) {
      counter = Object.values(this.filters)
        .filter((value) => Array.isArray(value))
        .reduce((total, arr) => total + arr.length, 0);
    }
    return counter;
  }
  loadUserSavedViews() {
    this.userSavedViewService
      .getUserSavedViews()
      .pipe(takeUntil(this._destroying$))
      .subscribe((views) => {
        this.UserViews = [...views];
        console.log(this.UserViews)
        if (views.length > 0) {
          const defaultView = views.find((view) => view.isDefault);
          this.filters = defaultView
            ? (JSON.parse(defaultView.filter) as FilterParams)
            : this.filters;
        }
        this.onFiltersChanged(this.filters);
      });
  }
  reloadUserSavedViews() {
    this.userSavedViewService
      .getUserSavedViews()
      .pipe(takeUntil(this._destroying$))
      .subscribe((views) => {
        this.UserViews = [...views];
      })
  }
  getFavouriteMedia(event: Event) {
    this.isFavourite = true;
    if (!this.filters) {
      this.filters = {
        isFavorite: false,
        organizationTypes: [],
        propertySubTypes: [],
        organizationAges: [],
        noOfEmployee: [],
        searchText: ''
      };
    }
    this.filters.isFavorite = this.isFavourite;
    const activeTabComponent = this.getActiveTabComponent();
    if (activeTabComponent) {
      activeTabComponent.pageNumber = 1;
      activeTabComponent.filters = this.filters;
    }
    this.loadDataForActiveTab();
  }

  resetPageNumber() {
    this.pageNumber = 1;
  }
  resetFilters() {
    this.filters = {
      searchText: '',
      organizationTypes: [],
      propertySubTypes: [],
      organizationAges: [],
      noOfEmployee: [],
      isFavorite: false,
    };
  }
  onSearchClick() {
    const text = this.searchForm.get('searchName')?.value;
    console.log(text)
    if (!this.filters) this.resetFilters();
    this.filters.searchText = text;
    const activeTabComponent = this.getActiveTabComponent();
    if (activeTabComponent) {
      activeTabComponent.pageNumber = 1;
      activeTabComponent.filters = this.filters;
    }
    this.loadDataForActiveTab();
  }
  onTabChange(activeTab: number) {
    this.activeTab = activeTab;
    this.resetSearchInputForm();
    setTimeout(() => {
      const activeTabComponent = this.getActiveTabComponent();
      if (activeTabComponent && activeTabComponent.pageNumber !== undefined) {
        activeTabComponent.pageNumber = 1;
        activeTabComponent.filters = this.filters
        if (this.isFavourite) {
          this.filters.isFavorite = this.isFavourite;
          this.filters.isFavorite = this.isFavourite
        }
      }

      this.loadDataForActiveTab();
    });
  }
  resetForm() {
    this.searchForm.reset();
    this.onSearchClick();
  }
  private loadDataForActiveTab() {
    switch (this.activeTab) {
      case 1:
        this.organizationTableComponent.getOrganizations();
        break;
      case 2:
        this.brandTableComponent.getBrands();
        break;
      case 3:
        this.propertyTableComponent.getProperties();
        break;
      default:
        this.organizationTableComponent.getOrganizations();
        break;
    }
  }
  private getActiveTabComponent(currentTab?: number) {
    if (currentTab) this.activeTab = currentTab;
    switch (this.activeTab) {
      case 1:
        return this.organizationTableComponent;
      case 2:
        return this.brandTableComponent;
      case 3:
        return this.propertyTableComponent;
      default:
        return null;
    }
  }
  private resetSearchInputForm() {
    this.searchForm.reset();
  }
  private getChangedRouteData() {
    switch (this.activeTab) {
      case 1:
        return {
          organizations: this.organizations,
          pageNumber: this.currentPageNumber,
          filters: this.filters,
        };
      case 2:
        return {
          brands: this.brands,
          pageNumber: this.currentPageNumber,
          filters: this.filters,
        };
      case 3:
        return {
          properties: this.properties,
          pageNumber: this.currentPageNumber,
          filters: this.filters,
        };
      default:
        return {};
    }
  }
  onTotalRecordsChange(count: number): void {
    this.totalRecords = count;
  }

  trackPageView(path: string) {
    this.gaService.pageView(`/find-media/${path}`);
  }
}
