import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { protectedResourceEndpoints } from '../shared/auth-config/auth-config';
import { ApiService } from '../shared/http/api.service';
import { Badge } from '../shared/models/Badge';
import { CreateBadge } from '../shared/models/CreateBadge';
import { HttpParams } from '@angular/common/http';
import { ApiResponse } from '../shared/models/ApiResponse';
import { PaginatedResponse } from '../shared/models/PaginatedResponse';
import { BadgeSummary } from '../shared/models/BadgeSummary';
import { PaginatedRequest } from '../shared/models/PaginatedRequest';
import { GetBadgeApplicationSummary } from '../shared/models/GetBadgeApplicationSummary';
import { BadgeApplicationSummary } from '../shared/models/BadgeApplicationSummary';

@Injectable({
  providedIn: 'root',
})
export class BadgeService {
  private readonly badgeUrl = protectedResourceEndpoints.badges.url;

  constructor(private apiService: ApiService) {}

  getBadge(id: string,languageId?:string): Observable<Badge> {
    let url = `${this.badgeUrl}${id}`;
    if (languageId) {
      url = `${url}?languageId=${languageId}`;
    }
    return this.apiService
      .getData<Badge>(url)
      .pipe(map((res) => res.result));
  }

  toggleFavourite(id: string): Observable<boolean> {
    return this.apiService
      .postData<boolean>(`${this.badgeUrl}${id}/favourite`, {})
      .pipe(map((res) => res.result));
  }

  getAllBadgeSummaries(
    body?: PaginatedRequest<{
      tagIds: string[];
      locationIds: string[];
      termTypeIds: string[];
      languageIds: string[];
      favourites: boolean;
      sortBy: string
    }>
  ): Observable<PaginatedResponse<BadgeSummary>> {
    return this.apiService
      .postData<PaginatedResponse<BadgeSummary>>(
        `${this.badgeUrl}summary`,
        body
      )
      .pipe(map((res) => res.result));
  }
  getBadgeApplicationRequirement(
    id: string
  ): Observable<ApiResponse<GetBadgeApplicationSummary>> {
    return this.apiService.getEntity<ApiResponse<GetBadgeApplicationSummary>>(
      `${this.badgeUrl}${id}/application-requirement`
    );
  }
  // Manage
  addBadge(badge: CreateBadge): Observable<ApiResponse<Badge>> {
    return this.apiService.postData(
      protectedResourceEndpoints.manageBadge.url,
      badge
    );
  }

  updateBadge(
    badgeId: string,
    badge: CreateBadge
  ): Observable<ApiResponse<Badge>> {
    return this.apiService.put<ApiResponse<Badge>>(
      `${protectedResourceEndpoints.manageBadge.url}${badgeId}`,
      badge
    );
  }

  deleteBadge(badgeId: string) {
    return this.apiService.delete(
      `${protectedResourceEndpoints.manageBadge.url}${badgeId}`
    );
  }
  getOrganisationBadgeListings(
    options: any
  ): Observable<ApiResponse<PaginatedResponse<CreateBadge>>> {
    return this.fetchBadgeListings(
      `${protectedResourceEndpoints.manageBadge.url}list`,
      options
    );
  }

  getUserOrganisationBadgeListings(
    options: any
  ): Observable<ApiResponse<PaginatedResponse<CreateBadge>>> {
    return this.fetchBadgeListings(
      `${protectedResourceEndpoints.manageBadge.url}list/me`,
      options
    );
  }
  getManageBadge(id: string) {
    return this.apiService.getEntity<ApiResponse<Badge>>(
      `${protectedResourceEndpoints.manageBadge.url}${id}`
    );
  }
  applicationSummary():Observable<ApiResponse<BadgeApplicationSummary[]>>{
    return this.apiService.getEntity<ApiResponse<BadgeApplicationSummary[]>>(`${protectedResourceEndpoints.manageBadge.url}summary`)
  }
  private fetchBadgeListings(
    endpoint: string,
    options: any
  ): Observable<ApiResponse<PaginatedResponse<CreateBadge>>> {
    const params = new HttpParams()
      .set('pageNumber', options.pageNumber)
      .set('pageSize', options.pageSize);

    return this.apiService.getEntity<
      ApiResponse<PaginatedResponse<CreateBadge>>
    >(endpoint, params);
  }
}
