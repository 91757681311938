import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, map, Observable, Subject, switchMap } from 'rxjs';
import { BadgeService } from 'src/app/services/badge.service';
import { Badge } from 'src/app/shared/models/Badge';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { GetBadgeComponent } from '../get-badge/get-badge.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AppContext } from 'src/app/shared/context/AppContext';

@Component({
  selector: 'app-badges-detail',
  templateUrl: './badges-detail.component.html',
  styleUrls: ['./badges-detail.component.css'],
})
export class BadgesDetailComponent implements OnInit, OnDestroy {
  _destroying$ = new Subject<void>();
  userLanguageId?: string;
  badge?: Badge;
  constructor(
    private activatedRoute: ActivatedRoute,
    private badgeService: BadgeService,
    private notificationService: NotificationService,
    private modalService: NgbModal,
    private translate: TranslateService,
    private appContext: AppContext
  ) {}

  ngOnInit(): void {
    const id$ = this.activatedRoute.params.pipe(map((params) => params.id));
    combineLatest([
          this.appContext.user,
          this.activatedRoute.params
        ]).pipe(
          switchMap(([user, params]) =>(this.userLanguageId =user.languageId,
            this.getBadge(params.id,user.languageId))
          )
        ).subscribe((badge) => {
          this.badge = badge;
        });
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  getBadge(id: string, languageId?:string): Observable<Badge> {
    return this.badgeService.getBadge(id,languageId);
  }

  copyLink() {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() =>
        this.notificationService.showSuccessToast('Link copied to clipboard')
      );
  }

  toggleFavourite() {
    if (!this.badge) return;

    this.badgeService
      .toggleFavourite(this.badge.badgeId)
      .subscribe((isFavourite) => {
        if (!this.badge) return;
        this.badge.isFavourite = isFavourite;
      });
  }
  showGetModal(id:string) {
    this.badgeService.getBadgeApplicationRequirement(id)
    .subscribe((res)=>{
    return new Promise<void>((resolve) => {
      this.modalService.dismissAll();
      const modalRef = this.modalService.open(GetBadgeComponent, {
        centered: true,
        windowClass: 'custom-modal-width'
      });
      modalRef.componentInstance.summary = res.result;
      resolve();
    });
})
  }
  ViewOriginal(badge: Badge) {
      this.getBadge(badge.badgeId)
      .subscribe((badge) => {
        this.badge = badge;
      })
    }
}
