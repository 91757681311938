import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, filter, mergeMap, takeUntil } from 'rxjs';
import { AppContext } from 'src/app/shared/context/AppContext';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { User } from 'src/app/shared/models/User';
import { Brand } from 'src/app/shared/models/brand.model';
import { OrganizationService } from 'src/app/services/organization.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserOrganization } from '../../../shared/models/UserOrganization';

@Component({
  selector: 'app-brands-list',
  templateUrl: './brands-list.component.html',
  styleUrls: ['./brands-list.component.css']
})
export class BrandsListComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();
  organizationName?: string;
  brands!: Brand[];
  orgId?: string;
  user!: User;
  constructor(
    private router: Router,
    private appContext: AppContext,
    private organizationService: OrganizationService,
    private route: ActivatedRoute) { }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  ngOnInit(): void {
    this.appContext.organization.pipe(
      filter((organization): organization is UserOrganization => !!organization),
      mergeMap((organization) => {
        this.organizationName = organization.organizationName;
        return this.organizationService.getOrganizationBrands(organization.organizationId)
      }),
      takeUntil(this._destroying$)
    ).subscribe((response) => {
      this.brands = ((response as ApiResponse).result) as Brand[];
    });
  }

  editBrand(event: any) {
    this.router.navigate(['edit'], {
      relativeTo: this.route,
      queryParams: { id: event.target.id },
    });
  }

  addBrand() {
    this.router.navigate(['add'], { relativeTo: this.route });
  }

}
