<div class="mb-3 row">
  <label class="col-sm-12 col-form-label fw-semibold" translate
    >{{ label }}
  </label>
  <div class="col-sm-12 col-md-3">
    <div class="dp-hidden position-absolute">
      <input
        class="form-control dp-hidden"
        ngbDatepicker
        #datepicker="ngbDatepicker"
        [autoClose]="'outside'"
        (dateSelect)="onDateSelection($event)"
        [displayMonths]="2"
        [dayTemplate]="t"
        outsideDays="hidden"
        [startDate]="startDate!"
        tabindex="-1"
        aria-label="From date"
      />
      <ng-template #t let-date let-focused="focused">
        <span
          class="custom-day"
          [class.focused]="focused"
          [class.range]="isRange(date)"
          [class.faded]="isHovered(date) || isInside(date)"
          (mouseenter)="hoveredDate = date"
          (mouseleave)="hoveredDate = null"
        >
          {{ date.day }}
        </span>
      </ng-template>
    </div>
    <div class="input-group">
      <input
        readonly
        #dpStartDate
        class="form-control"
        placeholder="yyyy-mm-dd"
        name="dpStartDate"
        [value]="formatter.format(startDate)"
        (input)="onStartDateInput(startDate, dpStartDate.value)"
      />
      <button
        class="btn btn-outline-secondary bi bi-calendar3"
        (click)="datepicker.toggle()"
        type="button"
      ></button>
    </div>
  </div>
  <div
    class="col-sm-12 col-md-1 d-flex justify-content-center align-items-center"
  >
    <span class="text-body-tertiary">{{ "Common.Until" | translate }}</span>
  </div>
  <div class="col-sm-12 col-md-3">
    <div class="input-group">
      <input
        #dpToDate
        class="form-control"
        placeholder="yyyy-mm-dd"
        name="dpToDate"
        readonly
        [value]="formatter.format(endDate)"
        (input)="onEndDateInput(endDate, dpToDate.value)"
        (blur)="(!!isFocused)"
      />
      <button
        class="btn btn-outline-secondary bi bi-calendar3"
        (click)="datepicker.toggle()"
        type="button"
      ></button>
    </div>
    <span class="col-sm-4"></span>
    
    <span class="text-danger col-sm-8" *ngIf="!endDate && isFocused">
      {{ "FinanceData.AddEntry.ToDateRequired" | translate }}
    </span>
  </div>
  <span class="text-danger mt-1 col-sm-8" *ngIf="!endDateValid">
    {{ "Common.InvalidDateRange" | translate }}
  </span>
</div>
