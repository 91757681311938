<form [formGroup]="viewForm" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <h4 class="modal-title save-view-header" translate>
      FindMedia.SaveToMyViews
    </h4>
    <div
      type="button"
      class="btn-off"
      data-coreui-dismiss="offcanvas"
      aria-label="Close"
      (click)="handleCancel()"
    ></div>
  </div>
  <div class="modal-body mb-3">
    <p translate>FindMedia.AssignNameToView</p>
    <p translate>FindMedia.AssignNameMaxChar</p>
    <input
      class="form-control"
      placeholder="Enter Name for selection"
      formControlName="viewName"
      maxlength="24"
    />
  </div>
  <div class="modal-footer custom-modal-footer">
    <button
      type="button"
      class="btn btn-secondary me-lg-5 me-sm-1"
      (click)="handleCancel()"
      translate
    >
      Common.Cancel
    </button>
    <button
      type="submit"
      *ngIf="action === 'rename'"
      class="btn btn-primary me-lg-4 me-sm-1"
      translate
    >
      Common.Rename
    </button>
    <button
      type="submit"
      *ngIf="action === 'save'"
      class="btn btn-primary me-lg-5 me-sm-1"
      translate
    >
      Common.Save
    </button>
  </div>
</form>
