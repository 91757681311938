import { Component, OnDestroy, OnInit, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, mergeMap, Subject, switchMap, takeUntil } from 'rxjs';
import { AppContext } from '../../../shared/context/AppContext';
import { Country } from '../../../shared/models/Country';
import { Gender } from '../../../shared/models/Gender';
import { Language } from '../../../shared/models/Language';
import { UserOrganization } from '../../../shared/models/UserOrganization';
import { UserProfile } from '../../../shared/models/UserProfile';
import { AuthService } from '../../../shared/services/auth.service';
import { UserService } from '../../../services/user.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AddOrganizationComponent } from '../add-organization/add-organization.component';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { OrganizationService } from '../../../services/organization.service';
import { CanRemoveUserFromOrg } from '../../../shared/models/CanRemoveUserFromOrg';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '../../../shared/models/User';

@Component({
  selector: 'app-profile',
  templateUrl: './userprofile.component.html',
  styleUrls: ['./userprofile.component.css'],
})
export class UserProfileComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();
  userProfile!: UserProfile;

  constructor(
    public appContext: AppContext,
    private userService: UserService,
    private organizationService: OrganizationService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private authService: AuthService,
    private offcanvasService: NgbOffcanvas,
    private modalService: NgbModal
  ) {}

  userProfileForm = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    countryId: new FormControl(undefined as unknown as number | undefined, [
      Validators.required,
    ]),
    languageId: new FormControl('', [Validators.required]),
    genderId: new FormControl(undefined as unknown as string | undefined, [
      Validators.required,
    ]),
    mvaNewsletter: new FormControl(false),
    orgVisibility: new FormArray<FormControl<boolean>>([]),
  });

  get firstName() {
    return this.userProfileForm.get('firstName');
  }
  get lastName() {
    return this.userProfileForm.get('lastName');
  }
  get countryId() {
    return this.userProfileForm.get('countryId');
  }
  get languageId() {
    return this.userProfileForm.get('languageId');
  }
  get genderId() {
    return this.userProfileForm.get('genderId');
  }
  get mvaNewsletter() {
    return this.userProfileForm.get('mvaNewsletter');
  }
  get orgVisibility() {
    return this.userProfileForm.get('orgVisibility') as FormArray;
  }

  ngOnInit(): void {
    this.userService
      .getUserProfileForEdit()
      .pipe(takeUntil(this._destroying$))
      .subscribe((userProfile) => {
        this.userProfile = userProfile;
        this.userProfile.user.userOrganizations.sort((a, b) =>
          a.organizationName.localeCompare(b.organizationName)
        );

        this.userProfile.user.userOrganizations.forEach((org) => {
          this.orgVisibility.push(new FormControl(org.visibleFromUser));
        });

        this.userProfileForm.patchValue(this.toFormValue(userProfile));
      });
  }

  toFormValue(userProfile: UserProfile) {
    return {
      firstName: userProfile.user.firstName,
      lastName: userProfile.user.lastName,
      countryId: userProfile.user.countryId,
      languageId: userProfile.user.languageId,
      genderId: userProfile.user.genderId,
      mvaNewsletter: userProfile.user.mvaNewsletter ?? false,
      orgVisibility: userProfile.user.userOrganizations.map(
        (o) => o.visibleFromUser
      ),
    };
  }

  toVisibilityApiPayload() {
    const formValue = this.orgVisibility.value as boolean[];

    return this.userProfile.user.userOrganizations.map((org, index) => ({
      organizationId: org.organizationId,
      visibleFromUser: formValue[index],
    }));
  }

  onDeleteDialog() {
    this.userService.getDeleteUserValidation().subscribe((validations) => {
      const orgNames = validations.orgNames
        ? validations.orgNames.join(',')
        : '';
      const yesEmitter = this.createRemoveModal(
        validations,
        orgNames,
        'UserProfile.DeleteUserTitle'
      );
      yesEmitter
        .pipe(
          mergeMap(() => this.userService.deleteUser()),
          takeUntil(this._destroying$)
        )
        .subscribe(() => {
          this.authService.logout();
        });
    });
  }

  onSubmit() {
    this.userProfileForm.markAllAsTouched();
    if (!this.orgVisibility.valid) return;

    this.userService
      .saveUserVisibility(this.toVisibilityApiPayload())
      .pipe(
        switchMap(() =>
          this.userService.saveUserProfile(this.userProfileForm.value as User)
        )
      )
      .subscribe((userProfile: UserProfile) => {
        this.userProfile = userProfile;
        this.notificationService.showSuccessToast(
          this.translate.instant('UserProfile.SaveSuccess')
        );
        this.appContext.setUser(userProfile.user);
      });
  }

  trackByUserOrganizations(index: number, item: UserOrganization) {
    return item.organizationId;
  }

  trackByLanguage(index: number, item: Language) {
    return item.languageId;
  }

  trackByGender(index: number, item: Gender) {
    return item.genderId;
  }

  trackByCountry(index: number, item: Country) {
    return item.countryId;
  }

  onDeleteBadge(badgeId: string) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
      centered: true,
    });

    modalRef.componentInstance.title = 'UserProfile.DeleteBadgeTitle';
    modalRef.componentInstance.bodyMessage = 'UserProfile.DeleteBadgeBody';
    modalRef.componentInstance.yesText = 'UserProfile.DeleteBadgeYes';
    modalRef.componentInstance.noText = 'UserProfile.DeleteBadgeNo';

    modalRef.componentInstance.yesAction
      .pipe(switchMap(() => this.userService.removeUserBadge(badgeId)))
      .subscribe(() => {
        this.userProfile.user.badges = this.userProfile.user.badges.filter(
          (x) => x.badgeId != badgeId
        );
        this.notificationService.showSuccessToast('Badge Removed');
        this.modalService.dismissAll();
      });
  }

  createRemoveModal(
    validations: CanRemoveUserFromOrg,
    organizationName: string,
    title: string
  ) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.bodyConfirm = 'ConfirmModal.ConfirmBody';
    modalRef.componentInstance.bodyMessage = validations.canRemove
      ? validations.isOrgRemoved
        ? 'UserProfile.RemoveUserFromAndDeleteOrg'
        : ''
      : 'UserProfile.RemoveUserFromOrgElevateAdmin';
    modalRef.componentInstance.bodyMessageParams = {
      orgName: organizationName,
    };
    modalRef.componentInstance.noText = validations.canRemove
      ? 'ConfirmModal.No'
      : 'ConfirmModal.Close';
    modalRef.componentInstance.yesText = validations.canRemove
      ? 'ConfirmModal.Yes'
      : '';
    return modalRef.componentInstance.yesAction as EventEmitter<void>;
  }

  removeOrganizationModal(item: UserOrganization) {
    this.organizationService
      .getRemoveUserValidations(item.organizationId)
      .subscribe((validations) => {
        const yesEmitter = this.createRemoveModal(
          validations,
          item.organizationName,
          'UserProfile.RemoveFromOrgTitle'
        );
        yesEmitter
          .pipe(
            mergeMap(() =>
              this.organizationService.removeUser(item.organizationId)
            ),
            takeUntil(this._destroying$)
          )
          .subscribe(() => {
            this.userProfile.user.userOrganizations =
              this.userProfile.user.userOrganizations.filter((x) => x != item);
            this.appContext.setUser(this.userProfile.user);
            this.notificationService.showSuccessToast(
              this.translate.instant('UserProfile.RemovedFromOrganization')
            );
            this.modalService.dismissAll();
          });
      });
  }

  onChangePassword() {
    this.authService.changePassword();
  }

  addOrganization() {
    this.offcanvasService.open(AddOrganizationComponent, {
      ariaLabelledBy: 'offcanvas-basic-title',
      position: 'end',
      backdrop: 'static',
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
