<div
  class="table-responsive solutions-table-container"
  infiniteScroll
  (scrolled)="onScroll()"
>
  <table class="table solutions-table" style="min-width: 800px;">
    <thead>
      <tr>
        <th></th>
        <th translate>Badges.ListingHeaders.Name</th>
        <th translate>Badges.ListingHeaders.Provider</th>
        <th translate>Badges.ListingHeaders.Tags</th>
        <th translate style="width: 10%"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let badge of badges">
        <td>
          <img
            [src]="badge.primaryImageUrl ?? '/assets/img/default-solution.png'"
            alt=""
            class="mx-3 logo"
          />
        </td>
        <td>
          <a [routerLink]="getBadgeLink(badge)">{{ badge.name }}</a>
        </td>
        <td>{{ badge.organizationName }}</td>
        <td class="w-25">
          <div
            *ngFor="let tag of badge.tags"
            class="badge badge-pill badge-pill-primary"
          >
            {{ tag }}
          </div>
        </td>
        <td>
          <button
            class="mr-xl-1 action-button"
            (click)="onLinkClick(popover, badge)"
            (mouseleave)="popover.close()"
            [ngbPopover]="linkPopover"
            #popover="ngbPopover"
            placement="bottom-right"
            container="body"
            triggers="manual"
          >
            <img src="/assets/icons/link.png" alt="" />
          </button>
          <ng-template #linkPopover>Link copied!</ng-template>
          <button
            class="action-button"
            (click)="toggleFavourite(favPopoverBtn, badge)"
            (mouseleave)="favPopoverBtn.close()"
            [ngbPopover]="favPopover"
            #favPopoverBtn="ngbPopover"
            placement="bottom-right"
            container="body"
            triggers="manual"
          >
            <img
              [src]="
                badge.isFavourite
                  ? '/assets/icons/favorite-filled.png'
                  : '/assets/icons/favorite.png'
              "
              alt=""
            />
          </button>
          <ng-template #favPopover>
            {{
              badge.isFavourite
                ? ("Common.AddedToFavourites" | translate)
                : ("Common.RemovedFromFavourites" | translate)
            }}
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="row text-center m-5" *ngIf="totalPages === 0">
    <h1 translate>Badges.NoMatchingRecords.Title</h1>
  </div>
  <div class="text-center" *ngIf="currentPage === totalPages">
    <h1 translate>Badges.EndOfResults</h1>
  </div>
</div>
