import { Component, Input } from '@angular/core';
import { MenuCategory, MenuItem } from 'src/app/shared/services/menu.service';

@Component({
  selector: 'app-menu-category',
  templateUrl: './menu-category.component.html',
  styleUrls: ['./menu-category.component.css'],
})
export class MenuCategoryComponent {
  @Input() menuCategory!: MenuCategory;
  @Input() depth: number = 0;

  toggleMenu(event: any, depth: number) {
    const show = event.target.classList.toggle('show');
    event.target.parentElement.classList.toggle('show', show);

    document
      .querySelectorAll('.navigation-title-' + depth)
      .forEach(function (item) {
        if (item !== event.target.parentElement) item.classList.remove('show');
      });
  }

  isMenuItem(item: MenuCategory | MenuItem): item is MenuItem {
    return 'path' in item;
  }
}
