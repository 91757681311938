<div
  class="table-responsive solutions-table-container"
  infiniteScroll
  (scrolled)="onScroll()"
>
  <table class="table solutions-table" style="min-width: 800px">
    <thead>
      <tr>
        <th></th>
        <th translate>Solutions.ListingHeaders.Name</th>
        <th translate>Solutions.ListingHeaders.Provider</th>
        <th translate>Solutions.ListingHeaders.Tags</th>
        <th translate style="width: 10%"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let sol of solutions">
        <td>
          <img
            [src]="sol.primaryImageUrl ?? '/assets/img/default-solution.png'"
            alt=""
            class="mx-3 logo"
          />
        </td>
        <td>
          <a [routerLink]="getSolutionLink(sol)">{{ sol.name }}</a>
        </td>
        <td>{{ sol.organizationName }}</td>
        <td class="w-25">
          <div
            *ngFor="let tag of sol.tags"
            class="badge badge-pill badge-pill-primary"
          >
            {{ tag }}
          </div>
        </td>
        <td>
          <button
            class="mr-xl-1 action-button"
            (click)="onLinkClick(popover, sol)"
            (mouseleave)="popover.close()"
            [ngbPopover]="linkPopover"
            #popover="ngbPopover"
            placement="bottom-right"
            container="body"
            triggers="manual"
          >
            <img src="/assets/icons/link.png" alt="" />
          </button>
          <ng-template #linkPopover>Link copied!</ng-template>
          <button
            class="action-button"
            (click)="toggleFavourite(favpopover, sol)"
          >
            <img
              [src]="
                sol.isFavourite
                  ? '/assets/icons/favorite-filled.png'
                  : '/assets/icons/favorite.png'
              "
              alt=""
              (mouseleave)="favpopover.close()"
              [ngbPopover]="favPopover"
              #favpopover="ngbPopover"
              placement="bottom-right"
              container="body"
              triggers="manual"
            />
          </button>
          <ng-template #favPopover>
            {{
              sol.isFavourite
                ? ("Common.AddedToFavourites" | translate)
                : ("Common.RemovedFromFavourites" | translate)
            }}
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="row text-center m-5" *ngIf="totalPages === 0">
    <h1 translate>Solutions.NoMatchingRecords.Title</h1>
    <p translate>Solutions.NoMatchingRecords.Caption</p>
  </div>
  <div class="text-center" *ngIf="currentPage === totalPages">
    <h1 translate>Solutions.EndOfResults</h1>
  </div>
</div>
