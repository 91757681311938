<div class="container  p-2 p-md-4">
  <!-- Title -->
  <div class="header-section">
    <div class="d-flex">
      <img
      [src]="'/assets/icons/my-solutions.svg'"
      [alt]="'Brand.BrandTitle' | translate"
      class="g-0 me-2 page-title-icon"
      />
      <h3 translate class="col gx-2">Solution.MySolutionsTitle</h3>
    </div>
  </div>

  <div class="row mb-3 mt-1">
    <!-- Subtitle -->
    <div class="col-lg-10 col-sm-10">
      <p translate>Solution.MySolutionsTitleNotes</p>
    </div>

    <!-- Add New Solution Button -->
    <div class="col-lg-2 col-sm-2">
      <div class="d-flex justify-content-end pb-4">
        <button
          class="btn btn-primary"
          type="button"
          translate
          routerLink="/manage/solutions/add"
        >
          Solution.AddNewSolution
        </button>
      </div>
    </div>
    <hr>
  </div>

  <!-- Nav Tabs -->
  <div class="scroll-container">
    <div class="d-flex gap-3 solution-nav-tabs">
      <a
        translate
        routerLink="."
        [queryParams]="{ type: 'org' }"
        class="nav-tab"
        routerLinkActive="active"
      >
        Solution.OrgSolutionsLink
      </a>

      <a
        translate
        class="nav-tab mr-auto"
        routerLink="."
        [queryParams]="{ type: 'me' }"
        routerLinkActive="active"
      >
        Solution.MySolutionsLink
      </a>

      <p
        *ngIf="totalItemCount !== null"
        class="align-self-center mb-0 d-none d-md-block"
      >
        {{ totalItemCount }} items found
      </p>
    </div>
  </div>

  <p *ngIf="totalItemCount !== null" class="mt-1 d-md-none">
    {{ totalItemCount }} items found
  </p>

  <!-- Table -->
  <div class="table-responsive mt-4" infiniteScroll (scrolled)="onScroll()">
    <table class="table">
      <thead>
        <tr>
          <th></th>
          <th translate>Solution.TableName</th>
          <th translate>Solution.TableCreated</th>
          <th translate>Solution.TableUploaded</th>
          <th translate>Solution.TableStatus</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let solution of solutions">
          <td align="center">
            <img
             [src]="solution.primaryImageUrl ?? '/assets/img/default-solution.png'"
              [id]="solution.solutionListingId"
              [title]="'Common.ViewDetails' | translate"
              class="round-image border border-secondary-subtle m-auto"
            />
          </td>
          <td class="pt-4">
              {{ solution.name }}
          </td>
          <td class="pt-4">{{ solution.createdOn | date : "MMM d, y" }}</td>
          <td class="pt-4">
            {{ solution.createdByUser.firstName }}
            {{ solution.createdByUser.lastName }}
          </td>
          <td class="pt-4">
            <active-status [active]="solution.active ?? false"></active-status>
          </td>
          <td class="text-center">
            <!-- Three-dot Dropdown Icon -->
            <div ngbDropdown container="body">
              <button
                dropdown-toggle
                btn
                p-0
                class="btn p-0 hide-arrow"
                ngbDropdownToggle
                aria-expanded="false"
              >
                <i class="bi bi-three-dots"></i>
              </button>
              <div
                ngbDropdownMenu
                class="dropdown-menu dropdown-menu-end custom-shadow p-0"
              >
                <button
                  ngbDropdownItem
                  translate
                  routerLink="/manage/solutions/edit"
                  [queryParams]="{ id: solution.solutionListingId }"
                >
                  <i class="bi bi-pencil-square me-2"></i>Common.Edit
                </button>
                <div class="dropdown-divider m-0"></div>
                <button
                  ngbDropdownItem
                  translate
                  class="text-danger"
                  (click)="deleteSolution(solution.solutionListingId!)"
                >
                  <i class="bi bi-trash me-2"></i>Common.Delete
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row text-center m-5" *ngIf="totalPages === 0">
    <h4 translate>Solutions.NoMatchingRecords.Title</h4>
    <p translate>Solutions.NoMatchingRecords.Caption</p>
  </div>
  <div class="text-center" *ngIf="currentPage === totalPages">
    <h4 translate>Solutions.EndOfResults</h4>
  </div>
</div>
