import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { OrganizationComponent } from './components/organization/organization.component';
import { HomeComponent } from './shared/components/home/home.component';
import { BrandsListComponent } from './components/brandmanagement/brands-list/brands-list.component';
import { AddEditBrandComponent } from './components/brandmanagement/add-edit-brand/add-edit-brand.component';
import { UserProfileComponent } from './components/userprofilemanagement/userprofile/userprofile.component';
import { UsersListComponent } from './components/usermanagement/users-list/users-list.component';
import { UsersGridComponent } from './components/mvapartner/users-grid/users-grid.component';
import { ManagePropertyComponent } from './components/propertydatamanagement/manage-property/manage-property.component';
import { PartnersGridComponent } from './components/mvasystemadmin/partners-grid/partners-grid.component';
import { JournalEntryGridComponent } from './components/financialdatamanagement/journal-entry-grid/journal-entry-grid.component';
import { ChangePasswordGuard } from './shared/guards/changepassword.guard';
import { NoAssignedOrganizationComponent } from './shared/components/no-assigned-organization/no-assigned-organization.component';
import { PrivacyPolicyComponent } from './shared/components/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './shared/components/terms-of-use/terms-of-use.component';
import { ReportComponent } from './components/reports/report/report.component';
import { ReportType, RoutePath } from './shared/app-constants/constants';
import { EmptyComponent } from './components/empty/empty.component';
import { ContactComponent } from './shared/components/contact/contact.component';
import { RouteGuard } from './shared/guards/route.guard';

import { ManageSolutionsListComponent } from './components/solutionmanagement/solutions-list/solutions-list.component';
import { AddEditSolutionListingComponent } from './components/solutionmanagement/add-edit-solution/add-edit-solution.component';
import { FindMediaComponent } from './components/media/findmedia/findmedia.component';
import { SingleOrganizationComponent } from './components/media/single-organization/single-organization.component';
import { SolutionsDetailComponent } from './components/solutions/detail/solutions-detail.component';
import { FindSolutionsComponent } from './components/find-solutions/find-solutions.component';
import { SolutionsTableComponent } from './components/solutions/table/solutions-table.component';
import { BadgesDetailComponent } from './components/badges/detail/badges-detail.component';
import { AddEditBadgeComponent } from './components/badgemanagement/add-edit-badge/add-edit-badge.component';
import { ManageBadgesListComponent } from './components/badgemanagement/badges-list/manage-badges-list.component';
import { BadgesTableComponent } from './components/badges/table/badges-table.component';
import { ManageBadgeDetailComponent } from './components/badgemanagement/manage-badge-detail/manage-badge-detail.component';
import { VisibilityComponent } from './components/visibility/visibility.component';
import { ApprovalCenterComponent } from './components/approvalcenter/approval-center/approval-center.component';
import { EnableSolutionsGuard } from './shared/guards/enableSolutions.guard';
import { FindSolutionsLegacyComponent } from './shared/components/find-solutions/find-solutions.component';
const routes: Routes = [
  {
    path: RoutePath.ChangePassword,
    component: EmptyComponent,
    canActivate: [ChangePasswordGuard],
  },
  {
    path: RoutePath.CountryReport,
    component: ReportComponent,
    data: { reportType: ReportType.Country },
    canActivate: [MsalGuard, RouteGuard],
  },
  {
    path: RoutePath.SocialMediaReport,
    component: ReportComponent,
    data: { reportType: ReportType.SocialMedia },
    canActivate: [MsalGuard, RouteGuard],
  },
  {
    path: RoutePath.WebsiteReport,
    component: ReportComponent,
    data: { reportType: ReportType.Website },
    canActivate: [MsalGuard, RouteGuard],
  },
  {
    path: RoutePath.ProfitLoss,
    component: ReportComponent,
    canActivate: [MsalGuard, RouteGuard],
    data: { reportType: ReportType.Journal },
  },
  {
    path: RoutePath.Empty,
    component: EmptyComponent,
    canActivate: [MsalGuard],
  },
  {
    path: RoutePath.Organization,
    component: OrganizationComponent,
    canActivate: [MsalGuard, RouteGuard],
  },
  {
    path: RoutePath.Brands,
    children: [
      {
        path: RoutePath.Empty,
        component: BrandsListComponent,
        canActivate: [MsalGuard, RouteGuard],
      },
      {
        path: RoutePath.BrandAdd,
        component: AddEditBrandComponent,
        canActivate: [MsalGuard, RouteGuard],
      },
      {
        path: RoutePath.BrandEdit,
        component: AddEditBrandComponent,
        canActivate: [MsalGuard, RouteGuard],
      },
    ],
  },
  // Manage Entities
  {
    path: RoutePath.Manage,
    canActivate: [MsalGuard, RouteGuard, EnableSolutionsGuard],
    children: [
      {
        path: RoutePath.Solutions,
        children: [
          {
            path: RoutePath.Empty,
            component: ManageSolutionsListComponent,
          },
          {
            path: RoutePath.SolutionAdd,
            component: AddEditSolutionListingComponent,
          },
          {
            path: RoutePath.SolutionEdit,
            component: AddEditSolutionListingComponent,
          },
        ],
      },
      {
        path: RoutePath.Badges,
        children: [
          {
            path: RoutePath.Empty,
            component: ManageBadgesListComponent,
          },
          {
            path: RoutePath.BadgesAdd,
            component: AddEditBadgeComponent,
          },
          {
            path: RoutePath.BadgesEdit,
            component: AddEditBadgeComponent,
          },
          {
            path: RoutePath.ManageBadgeDetail,
            component: ManageBadgeDetailComponent,
          },
        ],
      },
      { path: RoutePath.Visibility, component: VisibilityComponent },
      {
        path: RoutePath.ApprovalCenter,
        children: [
          {
            path: RoutePath.Empty,
            canActivate: [MsalGuard],
            component: ApprovalCenterComponent,
          },
        ],
      },
    ],
  },
  {
    path: RoutePath.UserProfile,
    component: UserProfileComponent,
    canActivate: [MsalGuard],
  },
  {
    path: RoutePath.Users,
    children: [
      {
        path: RoutePath.Empty,
        component: UsersListComponent,
        canActivate: [MsalGuard, RouteGuard],
      },
    ],
  },
  {
    path: RoutePath.TrustCenter,
    component: UsersGridComponent,
    canActivate: [MsalGuard, RouteGuard],
  },
  {
    path: RoutePath.DataLinks,
    children: [
      {
        path: RoutePath.Empty,
        component: ManagePropertyComponent,
        canActivate: [MsalGuard, RouteGuard],
      },
    ],
  },
  {
    path: RoutePath.Partners,
    component: PartnersGridComponent,
    canActivate: [MsalGuard, RouteGuard],
  },
  {
    path: RoutePath.Journal,
    component: JournalEntryGridComponent,
    canActivate: [MsalGuard, RouteGuard],
  },
  {
    path: RoutePath.OrgNotAssigned,
    component: NoAssignedOrganizationComponent,
    canActivate: [MsalGuard],
  },
  {
    path: RoutePath.PrivacyPolicy,
    component: PrivacyPolicyComponent,
    //canActivate: [MsalGuard]
  },
  {
    path: RoutePath.TermsOfService,
    component: TermsOfUseComponent,
    //canActivate: [MsalGuard]
  },
  {
    path: RoutePath.Home,
    component: HomeComponent,
    canActivate: [MsalGuard],
  },
  {
    path: RoutePath.Contact,
    component: ContactComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'find-media',
    canActivate: [MsalGuard, EnableSolutionsGuard],
    children: [
      { path: '', component: FindMediaComponent },
      { path: 'single/:id', component: SingleOrganizationComponent },
    ],
  },
  {
    path: 'find-solutions',
    canActivate: [MsalGuard, EnableSolutionsGuard],
    component: FindSolutionsComponent,
    children: [
      { path: 'solutions', component: SolutionsTableComponent },
      { path: 'badges', component: BadgesTableComponent },
      { path: '**', redirectTo: 'solutions' },
    ],
  },
  {
    path: 'find-solutions-v1',
    canActivate: [MsalGuard],
    component: FindSolutionsLegacyComponent,
  },
  {
    path: 'solutions/:id',
    canActivate: [MsalGuard, EnableSolutionsGuard],
    component: SolutionsDetailComponent,
  },
  {
    path: 'badges/:id',
    canActivate: [MsalGuard, EnableSolutionsGuard],
    component: BadgesDetailComponent,
  },
  {
    path: '**',
    component: EmptyComponent,
    canActivate: [MsalGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
