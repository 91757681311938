import { Component } from '@angular/core';

@Component({
  selector: 'app-find-solutions',
  templateUrl: './find-solutions.component.html',
  styleUrls: ['./find-solutions.component.css'],
})
export class FindSolutionsLegacyComponent {
  expandedRow: number | null = null; // Tracks which row is expanded

  // Static data for providers (e.g., logos, links)
  solutionProviders = [
    {
      id: 1,
      logo: 'assets/img/solutions/area.png',
      link: 'https://areaofeffect.tv',
    },
    {
      id: 2,
      logo: 'assets/img/solutions/logiq-agency.png',
      link: 'https://Logiq.agency',
    },
    {
      id: 3,
      logo: 'assets/img/solutions/constructive-news-mirror.png',
      link: 'https://constructiveinstitute.org/constructive-news-mirror/',
    },
    {
      id: 4,
      logo: 'assets/img/solutions/Harness.png',
      link: 'https://cite.org.zw/',
    },
    {
      id: 5,
      logo: 'assets/img/solutions/MediaDev.png',
      link: 'https://gfmd.info/find-funding/',
    },
    {
      id: 6,
      logo: 'assets/img/solutions/MediaDev.png',
      link: 'https://fundraising-guide.gfmd.info/',
    },
    {
      id: 7,
      logo: 'assets/img/solutions/Musajjel.png',
      link: 'https://www.musajjel.com/podcaster/login',
    },
    {
      id: 8,
      logo: 'assets/img/solutions/Remp.png',
      link: 'https://remp2030.com/',
    },
    {
      id: 9,
      logo: 'assets/img/solutions/Reporters.png',
      link: 'https://www.reporters-shield.org/',
    },
  ];

  // Toggle expanded row
  toggleExpand(index: number) {
    this.expandedRow = this.expandedRow === index ? null : index;
  }

  // Check if the row is expanded
  isExpanded(index: number): boolean {
    return this.expandedRow === index;
  }
}