import { Component, OnInit } from '@angular/core';
import { filter, map, mergeMap, Subject, switchMap, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FindMediaOrganizationBrand,
  FindMediaOrganizationDetail,
  FindMediaOrganizationProperty,
} from 'src/app/shared/models/OrganizationMedia';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'src/app/shared/services/clipboard.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { OrganizationService } from 'src/app/services/organization.service';
import { BrandManagementService } from 'src/app/services/brandmanagement.service';
import { PropertyManagementService } from 'src/app/services/propertymanagement.service';
import { AppContext } from 'src/app/shared/context/AppContext';
import { propertySubTypes } from 'src/app/shared/app-constants/constants';
@Component({
  selector: 'app-single-organization',
  templateUrl: './single-organization.component.html',
  styleUrls: ['./single-organization.component.css'],
})
export class SingleOrganizationComponent implements OnInit {
  private readonly _destroying$ = new Subject<void>();
  organization!: FindMediaOrganizationDetail;
  id: string | null = null;
  isLoading: boolean = true;
  toggledBrands: { [key: string]: boolean } = {};

  constructor(
    private route: ActivatedRoute,
    private organizationService: OrganizationService,
    private router: Router,
    private notificationService: NotificationService,
    private clipboardService: ClipboardService,
    private translate: TranslateService,
    private spinner: NgxSpinnerService,
    private brandService: BrandManagementService,
    private propertyService: PropertyManagementService,
    private appContext: AppContext
  ) {}
  ngOnInit(): void {
    this.loadOrganization();
  }
  loadOrganization() {
    this.spinner.show();
    this.route.paramMap
      .pipe(
        takeUntil(this._destroying$),
        map((params: { get: (arg0: string) => any }) => params.get('id')),
        filter((id) => id !== null),
        switchMap((id) =>
          this.appContext.user.pipe(
            map((user) => ({ user, id }))
          )
        ),
        mergeMap(({ user, id }) => this.organizationService.getOrganizationMediaById(id!,user.languageId))
      )
      .subscribe((org) => {
        this.organization = org;
        this.isLoading = false;
        this.spinner.hide();
      }),
      () => {
        this.isLoading = false;
        this.spinner.hide();
      };
  }

  get orgLanguages(): string {
    const properties = this.organization.brands.flatMap((b) => b.properties);
    const languages = properties.map((p) => p.primaryLanguage);
    const uniqueLanguages = [...new Set(languages)];

    return uniqueLanguages.join(', ');
  }

  getBrandExile(value?: boolean): string {
    const brandExile: { [key: string]: string } = {
      false: this.translate.instant('FindMedia.NotinExile'),
      true: this.translate.instant('FindMedia.OperatesinExile'),
    };

    return (
      brandExile[String(value)] ||
      this.translate.instant('FindMedia.NotApplicable')
    );
  }
  toggleBrand(brandId: string): void {
    this.toggledBrands[brandId] = !this.toggledBrands[brandId];
  }
  copyToClipboard() {
    const baseUrl = window.location.origin;
    const copyUrl = `${baseUrl}${this.router.url}`;
    this.clipboardService.copyToClipboard(copyUrl);
  }
  onOrgFavourite(orgId: string) {
    this.organizationService.favouriteOrganizationMedia(orgId).subscribe({
      next: (res: ApiResponse) => {
        const response = res.result as unknown as boolean;
        this.organization.isFavorite = response;
        response
          ? this.notificationService.showSuccessToast(
              this.translate.instant('FindMedia.AddedToFavorites')
            )
          : this.notificationService.showSuccessToast(
              this.translate.instant('FindMedia.RemovedFromFavorites')
            );
        this.loadOrganization();
      },
      error: () => {
        this.notificationService.showSuccessToast(
          this.translate.instant('FindMedia.Error')
        );
      },
    });
  }
  onBrandFavourite(brand: FindMediaOrganizationBrand) {
    this.brandService.favouriteBrandMedia(brand.brandId).subscribe({
      next: (res: ApiResponse) => {
        const response = res.result as unknown as boolean;
        brand.isFavorite = response;
        response
          ? this.notificationService.showSuccessToast(
              this.translate.instant('FindMedia.AddedToFavorites')
            )
          : this.notificationService.showSuccessToast(
              this.translate.instant('FindMedia.RemovedFromFavorites')
            );
      },
      error: () => {
        this.notificationService.showSuccessToast(
          this.translate.instant('FindMedia.Error')
        );
      },
    });
  }
  onPropertyFavourite(property: FindMediaOrganizationProperty) {
    this.propertyService.favouritePropertyMedia(property.propertyId).subscribe({
      next: (res: ApiResponse) => {
        const response = res.result as unknown as boolean;
        response
          ? this.notificationService.showSuccessToast(
              this.translate.instant('FindMedia.AddedToFavorites')
            )
          : this.notificationService.showSuccessToast(
              this.translate.instant('FindMedia.RemovedFromFavorites')
            );
        this.loadOrganization();
      },
      error: () => {
        this.notificationService.showSuccessToast(
          this.translate.instant('FindMedia.Error')
        );
      },
    });
  }
   getIcon(propertySubTypeId: string) {
      switch (propertySubTypeId) {
        case propertySubTypes.Facebook:
          return '../../../../assets/icons/fb-icn.svg';
        case propertySubTypes.Instagram:
          return '../../../../assets/icons/insta-icn.svg';
        case propertySubTypes.Youtube:
          return '../../../../assets/icons/yt-icn.svg';
        default:
          return '../../../../assets/icons/website-icn.svg';
      }
    }
}
