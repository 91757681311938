<div class="container p-2 p-md-4">
  <!-- Header Section -->
  <div class="header-section">
    <div  class="d-flex">
      <img src="../../../../assets/img/search.png" 
       class="me-2"/>
      <h3 translate>
        <!-- <i class="bi bi-search me-1"></i> -->
        
        FindMedia.Title
      </h3>
    </div>
    <p class="mt-1" translate>FindMedia.WelcomeMessage</p>
  </div>

  <!-- Search and Filter Row -->
  <div
    class="d-flex justify-content-between align-items-center flex-wrap flex-lg-nowrap"
  >
    <!-- Search Container -->
    <form class="w-100" [formGroup]="searchForm" (ngSubmit)="onSearchClick()">
      <div
        class="search-input-container d-flex flex-column flex-sm-row align-items-stretch w-100 mb-2 mb-lg-0"
      >
        <div class="position-relative flex-grow-1 mb-2 mb-sm-0">
          <i
            *ngIf="searchForm.get('searchName')?.value?.length == 0"
            class="bi bi-search position-absolute start-0 ms-2 mt-custom"
          ></i>
          <input
            type="text"
            name="searchName"
            class="form-control wide-input pe-5"
            formControlName="searchName"
            [placeholder]="'FindMedia.Search' | translate"
          />
          <button
          type="button"
            *ngIf="searchForm.get('searchName')?.value?.length > 0"
            (click)="resetForm()"
            class="btn btn-sm position-absolute top-50 translate-middle-y end-0 me-2 cancel-btn"
          >
            <i class="bi bi-x bold-icon"></i>
          </button>
        </div>
        <input type="submit" class="btn btn-search" translate
          FindMedia.Search
      >
      </div>
    </form>

    <!-- Filter Buttons -->
    <div
      class="filter-buttons d-flex flex-column flex-sm-row flex-md-row w-100 me-md-2"
    >
      <div
        class="btn-group mb-2 mb-sm-0 ms-md-auto"
        role="group"
        aria-label="Basic radio toggle button group"
      >
        <input
          type="radio"
          class="btn-check w-100"
          name="btnradio"
          id="btnradio1"
          autocomplete="off"
          (click)="allMedia($event)"
          checked
        />
        <label
          class="btn btn-outline-primary label-check-custom"
          for="btnradio1"
          translate
          >FindMedia.All</label
        >

        <input
          type="radio"
          class="btn-check"
          name="btnradio"
          id="btnradio2"
          autocomplete="off"
          (click)="getFavouriteMedia($event)"
        />
        <label
          class="btn btn-outline-primary btn-check-custom"
          for="btnradio2"
          translate
          >FindMedia.Favorites</label
        >
      </div>

      <button
        class="btn btn-primary btn-filter-custom btn-custom-padding ms-sm-2"
        (click)="filterDrawer.open()"
      >
        {{ "FindMedia.Filters" | translate }} ({{ filterCounter }})
      </button>
    </div>
  </div>

  <hr />
  <div
    class="container my-md-5"
    infiniteScroll
    (scrolled)="onScroll()"
    [infiniteScrollDistance]="0.5"
    [infiniteScrollThrottle]="300"
  >
    <div
      class="d-flex align-items-center justify-content-between"
      style="padding-bottom: 5px"
    >
      <ul
        ngbNav
        #nav="ngbNav"
        class="nav-tabs sticky-nav"
        style="min-width: 400px"
        [(activeId)]="activeTab"
        (activeIdChange)="onTabChange(activeTab)"
        destroyOnHide="true"
      >
        <li [ngbNavItem]="1" class="me-lg-5">
          <a ngbNavLink translate (click)="trackPageView('organizations')">FindMedia.Organizations</a>
          <ng-template ngbNavContent>
            <app-organization-table
              #organizationTableComponent
              (totalRecordsChange)="onTotalRecordsChange($event)"
            ></app-organization-table>
          </ng-template>
        </li>
        <li [ngbNavItem]="2" class="me-lg-5">
          <a ngbNavLink translate (click)="trackPageView('brands')">FindMedia.Brands</a>
          <ng-template ngbNavContent>
            <app-brand-table
              (totalRecordsChange)="onTotalRecordsChange($event)"
              #brandTableComponent
            >
            </app-brand-table>
          </ng-template>
        </li>
        <li [ngbNavItem]="3">
          <a ngbNavLink translate (click)="trackPageView('properties')">FindMedia.Properties</a>
          <ng-template ngbNavContent>
            <app-property-table
              (totalRecordsChange)="onTotalRecordsChange($event)"
              #propertyTableComponent
            >
            </app-property-table>
          </ng-template>
        </li>
      </ul>
      <span
        class="d-none d-md-block"
        translate
        [translateParams]="{ totalRecords: totalRecords }"
      >
        FindMedia.TotalResultMessage
      </span>
    </div>
    <div [ngbNavOutlet]="nav"></div>
  </div>
</div>

<div style="width: 10px !important">
  <app-filter
    #filterDrawer
    (reloadFilter)="loadUserSavedViews()"
    [SavedView]="UserViews"
    (reloadUserSavedViews)="reloadUserSavedViews()"
    (filtersChanged)="onFiltersChanged($event)"
  ></app-filter>
</div>
