<div class="mb-3 row">
  <label class="col-sm-12 col-md-8 col-form-label fw-semibold"
    >{{ label }}
  </label>
  <div class="col-sm-12 col-md-8">
    <div
      *ngFor="let url of values; let i = index; trackBy: trackByIndex"
      class="url-input-container"
    >
      <input
        type="url"
        appUrlValidator
        #urlInput="ngModel"
        [(ngModel)]="values[i]"
        placeholder="{{ 'Solution.LinkPlaceholder' | translate }}"
        class="form-control"
        (input)="onInputChange()"
        [ngClass]="urlInput.invalid && (urlInput.dirty || urlInput.touched) ? 'invalid-input' : ''"
      />
      <button
        *ngIf="i === values.length - 1"
        (click)="addUrl()"
        class="btn-icon-full ms-2"
      >
        <i class="bi bi-plus-square-fill"></i>
      </button>
      <button
        *ngIf="values.length > 1"
        (click)="removeUrl(i)"
        class="btn-icon-full ms-2"
      >
        <i class="bi bi-dash-square-fill"></i>
      </button>
      <div
      translate
      *ngIf="urlInput.errors?.invalidUrl" 
      class="text-danger ms-4"
    >
      Common.InvalidUrl
    </div>
    </div>
  </div>
</div>
