import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { filter, map, Subject, switchMap, takeUntil } from 'rxjs';
import { FindSolutionsFilterService } from 'src/app/services/find-solutions-filter.service';
import { SolutionListingService } from 'src/app/services/solution.listings.service';
import { SolutionListingSummary } from 'src/app/shared/models/SolutionListingSummary';

@Component({
  selector: 'app-solutions-table',
  templateUrl: './solutions-table.component.html',
  styleUrls: ['./solutions-table.component.css'],
})
export class SolutionsTableComponent implements OnInit, OnDestroy {
  solutions: SolutionListingSummary[] = [];
  totalPages = Infinity;
  currentPage = 1;
  pageSize = 15;
  totalItems = 0;

  tags: string[] = [];
  languages: string[] = [];
  locations: string[] = [];
  terms: string[] = [];
  sortBy : string = '';
  favourite = false;

  destroying$ = new Subject<void>();

  constructor(
    private solutionListingService: SolutionListingService,
    private activatedRoute: ActivatedRoute,
    private filterService: FindSolutionsFilterService
  ) {}

  ngOnInit() {
    this.filterService.filter$
      .pipe(
        filter((v): v is NonNullable<typeof v> => Boolean(v)),
        switchMap((filter) => {
          this.currentPage = 1;

          this.tags = filter.tags;
          this.languages = filter.languages;
          this.locations = filter.locations;
          this.terms = filter.terms;
          this.favourite = filter.fav === '1';
          this.sortBy = filter.sortBy;
          return this.fetchSolutions();
        }),
        takeUntil(this.destroying$)
      )
      .subscribe((solutions) => {
        this.solutions = solutions;
      });
  }

  ngOnDestroy() {
    this.destroying$.next();
    this.destroying$.complete();

    this.filterService.totalItems = null;
  }

  onScroll() {
    const nextPage = this.currentPage + 1;
    if (nextPage > this.totalPages) {
      return;
    }

    this.currentPage = nextPage;
    this.fetchSolutions().subscribe((solutions) => {
      this.solutions = [...this.solutions, ...solutions];
    });
  }

  getSolutionLink(solution: SolutionListingSummary) {
    return `/solutions/${solution.solutionListingId}`;
  }

  onLinkClick(popover: NgbPopover, solution: SolutionListingSummary) {
    const origin = window.location.origin;
    const path = this.activatedRoute.snapshot.url;
    const solutionId = solution.solutionListingId;

    window.navigator.clipboard
      .writeText(`${origin}/${path}/${solutionId}`)
      .then(() => popover.open());
  }

  toggleFavourite(popover: NgbPopover,solution: SolutionListingSummary) {
    const id = solution.solutionListingId;

    this.solutionListingService.toggleFavourite(id).subscribe((res) => {
      solution.isFavourite = res;
      popover.open()
    });
  }

  fetchSolutions() {
    return this.solutionListingService
      .getAllSolutionListingSummaries({
        pageNumber: this.currentPage,
        pageSize: this.pageSize,
        filter: {
          tagIds: this.tags,
          locationIds: this.locations,
          termTypeIds: this.terms,
          languageIds: this.languages,
          favourites: this.favourite,
          sortBy : this.sortBy
        },
      })
      .pipe(
        map((res) => {
          this.totalPages = res.totalPages;
          this.filterService.totalItems = res.totalItems;
          return res.results;
        })
      );
  }
}
