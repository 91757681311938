import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  Subject,
  combineLatest,
  filter,
  mergeMap,
  of,
  takeUntil,
  tap,
  switchMap,
} from 'rxjs';
import { PropertyManagementService } from 'src/app/services/propertymanagement.service';
import { AppContext } from 'src/app/shared/context/AppContext';
import { Country } from 'src/app/shared/models/Country';
import { GeoGraphicReach } from 'src/app/shared/models/GeoGraphicReach';
import { GlobalLanguage } from 'src/app/shared/models/GlobalLanguage';
import { PropertyContentPriority } from 'src/app/shared/models/PropertyContentPriority';
import { Goal } from 'src/app/shared/models/Goal';
import { PropertySaveEventData } from 'src/app/shared/models/PropertySaveEvent';
import { PropertySubType } from 'src/app/shared/models/PropertySubType';
import { UserOrganization } from 'src/app/shared/models/UserOrganization';
import { Property } from 'src/app/shared/models/property.model';
import { MasterDataService } from 'src/app/shared/services/master-data.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { propertySubTypes } from 'src/app/shared/app-constants/constants';
import { DomainValidator } from '../../../shared/validators/domain.Validator';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';

const customLangNoVal = 'NOVAL';

@Component({
  selector: 'app-add-edit-property',
  templateUrl: './add-edit-property.component.html',
  styleUrls: ['./add-edit-property.component.css'],
})
export class AddEditPropertyComponent implements OnInit, OnDestroy {
  @Input() propertyId!: string;
  @Input() brandId!: string;
  @Input() orgId!: string;

  @Output() PropertySaved = new EventEmitter<PropertySaveEventData>();
  @Output() PropertySaveCancelled = new EventEmitter();

  @ViewChildren('goalsElemet') private goalsElements!: QueryList<ElementRef>;
  @ViewChildren('contentPriorityElemet')
  private contentPriorityElemet!: QueryList<ElementRef>;

  @ViewChild('deletePropertyModal') deletePropertyModal: ElementRef | undefined;
  errorCodesMap = new Map<string, () => AbstractControl<any, any> | null>([
    ['PROPERTY_NAME_ALREADY_EXISTS', () => this.propName],
    ['PROPERTY_URL_ALREADY_EXISTS', () => this.propUrl],
    ['INVALID_DOMAIN', () => this.propUrl],
  ]);

  propertyName?: string;
  propertyUrl?: string;
  propertySubTypeId?: string;
  propertyCountryId?: number;
  geographicReachId?: string;
  primaryLanguage?: string;
  valueEffectiveness?: string;
  goalReach: boolean = false;
  goalEnggagement: boolean = false;
  goalLoyalty: boolean = false;
  goalRevenue: boolean = false;
  trackProperty: boolean = false;
  trackFinancialPerformance: boolean = false;
  propertyContentPriorityId?: string;
  customLang?: string;
  website: string = propertySubTypes.Website;
  propertyData?: Property;

  property: Property | null = null;
  otherLanguage: boolean = false;

  private readonly _destroying$ = new Subject<void>();
  languageId!: string;
  userOrganizationId!: string;
  vaidationErrors: string[] = [];
  propertyNameExistsError = false;
  propertyUrlExistsError = false;

  countries: Country[] = [];
  geographicalReaches: GeoGraphicReach[] = [];
  goalsMasterList: Goal[] = [];
  propertyContentPriorities: PropertyContentPriority[] = [];
  propertySubTypes: PropertySubType[] = [];
  globalLanguages: GlobalLanguage[] = [];
  selectedGoals: string[] = [];
  selectedPropertyContentPriorityId: string | null = null;

  addEditPropertyForm!: FormGroup;
  propSubTypeValue: string | undefined;

  get propSubType() {
    return this.addEditPropertyForm.get('propSubType');
  }
  get propName() {
    return this.addEditPropertyForm.get('propName');
  }
  get propUrl() {
    return this.addEditPropertyForm.get('propUrl');
  }
  get primaryLang() {
    return this.addEditPropertyForm.get('primaryLang');
  }
  get customLanguage() {
    return this.addEditPropertyForm.get('customLanguage');
  }
  get propCountry() {
    return this.addEditPropertyForm.get('propCountry');
  }
  get geographicReach() {
    return this.addEditPropertyForm.get('geographicReach');
  }
  get valueEffectivenessControl() {
    return this.addEditPropertyForm.get('valueEffectivenessControl');
  }
  get goals() {
    return this.addEditPropertyForm.get('goals');
  }
  get contentPriority() {
    return this.addEditPropertyForm.get('contentPriority');
  }
  get trackPropertyControl() {
    return this.addEditPropertyForm.get('trackPropertyControl');
  }
  get trackFinancialPerformanceControl() {
    return this.addEditPropertyForm.get('trackFinancialPerformanceControl');
  }

  constructor(
    private masterDataService: MasterDataService,
    private translate: TranslateService,
    private propertyManagementServie: PropertyManagementService,
    private notificationService: NotificationService,
    public appContext: AppContext,
    private formBuilder: FormBuilder,
    private modalService: NgbModal
  ) {}

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
  ngOnInit(): void {
    combineLatest([
      this.appContext.user.pipe(
        mergeMap((user) =>
          this.masterDataService.getPropertyMasterData(user.languageId)
        )
      ),
      this.appContext.organization.pipe(
        filter(
          (organization): organization is UserOrganization => !!organization
        ),
        tap((organization) => {
          this.userOrganizationId = organization.organizationId;
        }),
        mergeMap((organization) => {
          return this.propertyId
            ? this.propertyManagementServie.getProperty(
                this.propertyId,
                this.userOrganizationId
              )
            : of(null);
        })
      ),
    ])
      .pipe(takeUntil(this._destroying$))
      .subscribe(([propertyMasterData, property]) => {
        const masterdata = propertyMasterData;
        this.propertySubTypes = masterdata.propertySubTypes;
        this.globalLanguages = masterdata.globalLanguages;
        this.globalLanguages.unshift({
          languageCode: 'other',
          languageId: '-1',
          englishName: 'Other',
        });
        this.countries = masterdata.countries;
        this.geographicalReaches = masterdata.geographicReaches;
        this.goalsMasterList = masterdata.goals;
        this.propertyContentPriorities = masterdata.propertyContentPriorities;

        this.propertyData = property?.result as Property;
        this.selectedPropertyContentPriorityId = this.propertyData
          ? this.propertyData.propertyContentPriorityId
          : null;
        this.InitializeFoarm(this.propertyData);
      });
  }

  onDeleteBadge(badgeId: string) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.title = 'Property.DeleteBadgeTitle';
    modalRef.componentInstance.bodyMessage = 'Property.DeleteBadgeBody';
    modalRef.componentInstance.yesText = 'Property.DeleteBadgeYes';
    modalRef.componentInstance.noText = 'Property.DeleteBadgeNo';

    modalRef.componentInstance.yesAction
      .pipe(
        switchMap(() =>
          this.propertyManagementServie.removeBadge(this.propertyId, badgeId)
        )
      )
      .subscribe(() => {
        this.propertyData!.badges = this.propertyData!.badges.filter(
          (x) => x.badgeId !== badgeId
        );
        this.notificationService.showSuccessToast('Badge Removed');
        this.modalService.dismissAll();
      });
  }

  private InitializeFoarm(property: Property) {
    this.addEditPropertyForm = new FormGroup({
      propSubType: new FormControl(
        property ? property.propertySubTypeId : this.propertySubTypeId,
        [Validators.required]
      ),
      propName: new FormControl(
        property ? property.propertyName : this.propertyName,
        [Validators.required]
      ),
      propUrl: new FormControl(
        property ? property.propertyUrl : this.propertyUrl,
        [Validators.required, DomainValidator(this.propSubTypeValue)]
      ),
      primaryLang: new FormControl(this.primaryLanguage, [Validators.required]),
      customLanguage: new FormControl(this.customLang, [Validators.required]),
      propCountry: new FormControl(
        property ? property.primaryCountryId : this.propertyCountryId,
        [Validators.required]
      ),
      geographicReach: new FormControl(
        property ? property.geographicReachId : this.geographicReachId,
        [Validators.required]
      ),
      valueEffectivenessControl: new FormControl(
        property ? property.valueEffectiveness : this.valueEffectiveness
      ),
      goals: this.formBuilder.record(
        Object.assign(
          {},
          ...this.goalsMasterList.map((goal) => {
            return {
              [goal.goalId]: property
                ? property.propertyGoals.includes(goal.goalId)
                : false,
            };
          })
        )
      ),
      contentPriority: this.formBuilder.record(
        Object.assign(
          {},
          ...this.propertyContentPriorities.map((priority) => {
            return {
              [priority.propertyContentPriorityId]: property
                ? property.propertyContentPriorityId
                : false,
            };
          })
        )
      ),
      trackPropertyControl: new FormControl(
        property ? property.trackProperty : true
      ),
      trackFinancialPerformanceControl: new FormControl(
        property ? property.trackFinancialPerformance : true
      ),
    });

    if (
      property &&
      !this.globalLanguages
        .map((x) => x.englishName)
        .includes(property.primaryLanguage)
    ) {
      this.otherLanguage = true;
      this.primaryLang?.setValue('Other');
      this.customLanguage?.setValue(property.primaryLanguage);
    } else {
      this.customLanguage?.setValue(customLangNoVal); // to avaoid validation error
      this.primaryLang?.setValue(
        property ? property.primaryLanguage : this.primaryLanguage
      );
    }
  }

  trackByGoalId(index: number, item: Goal) {
    return item.goalId;
  }

  trackByContentPriorityId(index: number, item: PropertyContentPriority) {
    return item.propertyContentPriorityId;
  }

  onLanguageChange(event: any) {
    if (event.target.value === 'Other') {
      this.otherLanguage = true;
      if (this.customLanguage?.value === customLangNoVal) {
        this.customLanguage?.setValue('');
      }
    } else {
      this.otherLanguage = false;
      this.customLanguage?.setValue(customLangNoVal); // to avaoid validation error
    }
  }

  saveProperty() {
    let propertyId: string;
    propertyId =
      this.propertyId === ''
        ? '00000000-0000-0000-0000-000000000000'
        : this.propertyId;
    this.addEditPropertyForm.markAllAsTouched();
    if (this.addEditPropertyForm.valid) {
      let language =
        this.primaryLang?.value === 'Other'
          ? this.customLanguage?.value
          : this.primaryLang?.value;

      let propertyData: Property = {
        brandId: this.brandId,
        organizationId: this.orgId,
        propertySubTypeId: this.propSubType?.value,
        propertyName: this.propName?.value,
        propertyUrl: this.propUrl?.value,
        primaryLanguage: language,
        primaryCountryId: this.propCountry?.value,
        geographicReachId: this.geographicReach?.value,
        valueEffectiveness: this.valueEffectivenessControl?.value,
        trackProperty: this.trackPropertyControl?.value,
        trackFinancialPerformance: this.trackFinancialPerformanceControl?.value,
        propertyContentPriorityId: this.selectedPropertyContentPriorityId,
        propertyId: propertyId,
        propertyGoals: Object.entries(this.goals?.value)
          .filter((x) => x[1])
          .map((x) => x[0]),
        solutions: [],
        badges: [],
      };

      if (propertyId == '00000000-0000-0000-0000-000000000000') {
        // add new  property
        this.propertyManagementServie.addProperty(propertyData).subscribe({
          next: (property) => {
            this.notificationService.showSuccessToast(
              this.translate.instant('Property.PropertyCreated')
            );
            const propertySaveEnevtData = {
              property: property,
              isCreate: true,
            } as PropertySaveEventData;

            if (property.propertyId != '00000000-0000-0000-0000-000000000000') {
              this.PropertySaved.emit(propertySaveEnevtData);
            } else {
              this.PropertySaved.emit({
                isCreateOrgUser: true,
              } as PropertySaveEventData);
            }
          },
          error: (error) => {
            if (!error.error.isSuccess) {
              if (error?.status === 400 && error.error?.errorCodes) {
                error.error.errorCodes.forEach((errorCode: string) => {
                  this.errorCodesMap
                    .get(errorCode)
                    ?.call(this)
                    ?.setErrors({ [errorCode]: true });
                });
                this.addEditPropertyForm.setErrors({
                  Errors: true,
                  ErrorCodes: error.error.errorCodes.map(
                    (c: string) => `ErrorCodes.${c}`
                  ),
                });
              } else if (error.status == '404') {
                let errorMessage: string = '';
                errorMessage = this.translate.instant(
                  'ErrorCodes.' + error.error.errorCode
                );
                this.notificationService.showErrorToast(errorMessage);
              }
            }
          },
        });
      } else {
        // update property
        this.propertyManagementServie
          .saveProperty(propertyId, propertyData)
          .subscribe({
            next: (property) => {
              this.notificationService.showSuccessToast(
                this.translate.instant('Property.PropertySaved')
              );
              const propertySaveEnevtData = {
                property: property,
                isCreate: false,
              } as PropertySaveEventData;
              this.PropertySaved.emit(propertySaveEnevtData);
            },
            error: (error) => {
              if (!error.error.isSuccess) {
                if (error?.status === 400 && error.error?.errorCodes) {
                  error.error.errorCodes.forEach((errorCode: string) => {
                    this.errorCodesMap
                      .get(errorCode)
                      ?.call(this)
                      ?.setErrors({ [errorCode]: true });
                  });
                  this.addEditPropertyForm.setErrors({
                    Errors: true,
                    ErrorCodes: error.error.errorCodes.map(
                      (c: string) => `ErrorCodes.${c}`
                    ),
                  });
                } else if (error.status == '404') {
                  let errorMessage: string = '';
                  errorMessage = this.translate.instant(
                    'ErrorCodes.' + error.error.errorCodes[0]
                  );
                  this.notificationService.showErrorToast(errorMessage);
                }
              }
            },
          });
      }
    }
  }

  onCancel() {
    this.PropertySaveCancelled.emit();
  }

  onDelete() {
    this.propertyManagementServie
      .deleteProperty(this.propertyData?.propertyId!, this.orgId)
      .subscribe({
        next: (response) => {
          if (response.isSuccess) {
            this.notificationService.showSuccessToast(
              'Property deleted successfully.'
            );
            this.closeDialog();
            const propertySaveEventData = {
              isDelete: true,
              property: this.propertyData,
            } as PropertySaveEventData;
            this.PropertySaved.emit(propertySaveEventData);
          }
        },
        error: (error) => {
          if (!error.error.isSuccess) {
            if (error.status == '404') {
              let errorMessage: string = '';
              errorMessage = this.translate.instant(
                'ErrorCodes.' + error.error.errorCodes[0]
              );
              this.notificationService.showErrorToast(errorMessage);
            }
          }
        },
      });
  }
  onSelectChange(event: any) {
    this.propSubTypeValue = this.propertySubTypes.find(
      (e) => e.propertySubtypeId === event.target.value
    )?.name;
    this.addEditPropertyForm
      .get('propUrl')
      ?.setValidators([
        Validators.required,
        DomainValidator(this.propSubTypeValue),
      ]);
    this.addEditPropertyForm.get('propUrl')?.updateValueAndValidity();
  }
  showDeleteDialog() {
    this.deletePropertyModal!.nativeElement.style.display = 'block';
  }

  closeDialog() {
    this.deletePropertyModal!.nativeElement.style.display = 'none';
  }
}
