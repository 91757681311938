import { Injectable } from '@angular/core';
import { protectedResourceEndpoints } from '../shared/auth-config/auth-config';
import { CreateSolutionListing } from 'src/app/shared/models/CreateSolutionListing';
import { ApiService } from '../shared/http/api.service';
import { ApiResponse } from '../shared/models/ApiResponse';
import { Paginated } from '../shared/models/Paginated';
import { map, Observable } from 'rxjs';
import { SolutionListingSummary } from '../shared/models/SolutionListingSummary';
import { PaginatedRequest } from '../shared/models/PaginatedRequest';
import { SolutionListing } from '../shared/models/SolutionListing';
import { PaginatedResponse } from '../shared/models/PaginatedResponse';

@Injectable({
  providedIn: 'root',
})
export class SolutionListingService {
  private solutionListingsUrl = protectedResourceEndpoints.solutionListings.url;
  private solutionListingsManageUrl =
    protectedResourceEndpoints.solutionListingsManage.url;

  constructor(private apiservice: ApiService) {}

  getSolutionListing(solutionListingId: string, languageId?:string): Observable<SolutionListing> {
    let url = `${this.solutionListingsUrl}${solutionListingId}`;
      if (languageId) {
        url = `${url}?languageId=${languageId}`;
      }
    return this.apiservice
      .getData<SolutionListing>(url)
      .pipe(map((response) => response.result));
  }

  getAllSolutionListings(
    body?: any
  ): Observable<Paginated<CreateSolutionListing>> {
    return this.apiservice
      .postData<Paginated<CreateSolutionListing>>(
        this.solutionListingsUrl,
        body ?? {}
      )
      .pipe(map((response) => response.result));
  }

  getAllSolutionListingSummaries(
    body?: PaginatedRequest<{
      tagIds: string[];
      locationIds: string[];
      termTypeIds: string[];
      languageIds: string[];
      favourites: boolean;
      sortBy : string
    }>
  ): Observable<Paginated<SolutionListingSummary>> {
    return this.apiservice
      .postData<Paginated<SolutionListingSummary>>(
        `${this.solutionListingsUrl}summary`,
        body ?? {}
      )
      .pipe(map((response) => response.result));
  }

  toggleFavourite(solutionListingId: string): Observable<boolean> {
    return this.apiservice
      .postData<boolean>(
        `${this.solutionListingsUrl}${solutionListingId}/favourite`
      )
      .pipe(map((response) => response.result));
  }

  // Manage

  getOrganisationSolutionListings(
    options?: any
  ): Observable<PaginatedResponse<SolutionListing>> {
    return this.apiservice
      .postData<PaginatedResponse<SolutionListing>>(
        `${this.solutionListingsManageUrl}list`,
        options ?? {}
      )
      .pipe(map((response) => response.result));
  }

  getMySolutionListings(
    options?: any
  ): Observable<PaginatedResponse<SolutionListing>> {
    return this.apiservice
      .postData<PaginatedResponse<SolutionListing>>(
        `${this.solutionListingsManageUrl}list/me`,
        options ?? {}
      )
      .pipe(map((response) => response.result));
  }

  getEditSolutionListing(solutionListingId: string) {
    return this.apiservice.getEntity<ApiResponse>(
      `${this.solutionListingsManageUrl}${solutionListingId}`
    );
  }

  addSolutionListing(solutionListing: CreateSolutionListing) {
    return this.apiservice.postData(
      this.solutionListingsManageUrl,
      solutionListing
    );
  }

  saveSolutionListing(solutionListingId: string, brand: CreateSolutionListing) {
    return this.apiservice.put<ApiResponse<CreateSolutionListing>>(
      `${this.solutionListingsManageUrl}${solutionListingId}`,
      brand
    );
  }

  deleteSolutionListing(solutionListingId: string) {
    return this.apiservice.delete(
      `${this.solutionListingsManageUrl}${solutionListingId}`
    );
  }
}
