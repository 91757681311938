import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.css'],
})
export class DateRangeComponent implements OnInit, OnDestroy {
  endDateValid: boolean = true;
  @Input() label!: string;
  @Input() startDate: NgbDate | null = null;
  @Input() endDate: NgbDate | null = null;
  @Output() dateRangeChange = new EventEmitter<{
    from: NgbDate | null;
    to: NgbDate | null;
    valid: boolean
  }>();

  private readonly _destroying$ = new Subject<void>();

  isFocused = false;

  constructor() {}

  calendar = inject(NgbCalendar);
  formatter = inject(NgbDateParserFormatter);
  hoveredDate: NgbDate | null = null;

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  ngOnInit(): void {}

  isHovered(date: NgbDate) {
    return (
      this.startDate &&
      !this.endDate &&
      this.hoveredDate &&
      date.after(this.startDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return (
      this.endDate && date.after(this.startDate) && date.before(this.endDate)
    );
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.startDate) ||
      (this.endDate && date.equals(this.endDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  onDateSelection(date: NgbDate) {
    if (!this.startDate && !this.endDate) {
      this.startDate = date;
    }else if (
      this.startDate &&
      !this.endDate &&
      date &&
      date.equals(this.startDate)
    ) {
      // Deselect start date if clicked again
      this.startDate = null;
    } 
    else if (
      this.startDate &&
      !this.endDate &&
      date &&
      date.after(this.startDate)
    ) {
      this.endDate = date;
    } else {
      this.endDate = null;
      this.startDate = date;
    }

    this.rangeDidChange();
  }

  onStartDateInput(currentValue: NgbDate | null, input: string | null) {
    if (input && input !== '') {
      const parsed = this.formatter.parse(input);

      this.startDate =
        parsed && this.calendar.isValid(NgbDate.from(parsed))
          ? NgbDate.from(parsed)
          : currentValue;
      this.validateDateRange()
    } else {
      this.startDate = null;
    }

    this.rangeDidChange();
  }

  onEndDateInput(currentValue: NgbDate | null, input: string) {
    if (input && input !== '') {
      const parsed = this.formatter.parse(input);

      this.endDate =
        parsed && this.calendar.isValid(NgbDate.from(parsed))
          ? NgbDate.from(parsed)
          : currentValue;
      this.validateDateRange()
    } else {
      this.endDate = null;
    }

    this.rangeDidChange();
  }

  rangeDidChange() {
    this.dateRangeChange.emit({ from: this.startDate, to: this.endDate, valid: this.endDateValid });
  }
  validateDateRange(): void {
    if (this.startDate && this.endDate) {
      this.endDateValid = !this.endDate.before(this.startDate);
      if (!this.endDateValid) {
        this.endDate = null; 
      }
    } else {
      this.endDateValid = true; 
    }
  }
}
