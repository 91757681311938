import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import {
  combineLatest,
  filter,
  mergeMap,
  of,
  Subject,
  takeUntil,
  tap,
} from 'rxjs';
import { BadgeService } from 'src/app/services/badge.service';
import { RoutePath } from 'src/app/shared/app-constants/constants';
import { AppContext } from 'src/app/shared/context/AppContext';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { ApplyType } from 'src/app/shared/models/ApplyType';
import { Base64File } from 'src/app/shared/models/Base64File';
import { Country } from 'src/app/shared/models/Country';
import { CreateBadge } from 'src/app/shared/models/CreateBadge';
import { GlobalLanguage } from 'src/app/shared/models/GlobalLanguage';
import { Language } from 'src/app/shared/models/Language';
import { PublishType } from 'src/app/shared/models/PublishType';
import { Tag } from 'src/app/shared/models/Tag';
import { TermType } from 'src/app/shared/models/TermType';
import { UserOrganization } from 'src/app/shared/models/UserOrganization';
import { MasterDataService } from 'src/app/shared/services/master-data.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-add-edit-badge',
  templateUrl: './add-edit-badge.component.html',
  styleUrls: ['./add-edit-badge.component.css'],
})
export class AddEditBadgeComponent implements OnInit, OnDestroy {
  @ViewChild('badgeForm') ngForm: NgForm | null | undefined;
  @ViewChild('deleteBadgeModal') deleteBadgeModal: ElementRef | undefined;
  private readonly _destroying$ = new Subject<void>();
  badgeListingId: string | null = null;
  badgeListing: CreateBadge = new CreateBadge();

  validFrom: NgbDate | null = null;
  validTo: NgbDate | null = null;

  organizationName?: string;
  organizationId!: string;
  primaryImageErrors: string[] = [];
  badgesImageErrors: string[] = [];
  allowedImageFileTypes: string[] = ['image/jpeg', 'image/png'];
  selectedPropertyId!: string;
  isEditMode: boolean = false;
  vaidationErrors: string[] = [];
  applyTypes: ApplyType[] = [];
  termTypes: TermType[] = [];
  tags: Tag[] = [];
  publishTypes: PublishType[] = [];
  countries: Country[] = [];
  languages: GlobalLanguage[] = [];
  applyToSettings: IDropdownSettings = {};
  languagesSettings: IDropdownSettings = {};
  locationsSettings: IDropdownSettings = {};
  tagsSettings: IDropdownSettings = {};
  isFormSubmitted = false;
  urlListValid: boolean = true;
  isDateRangeValid: boolean = true;
  readonly BADGE_PATH = `/${RoutePath.Manage}/${RoutePath.Badges}`;

  constructor(
    private masterDataService: MasterDataService,
    private badgeService: BadgeService,
    private appContext: AppContext,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService
  ) { }
  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
  ngOnInit(): void {
    this.applyToSettings = {
      enableCheckAll: false,
      idField: 'applyTypeId',
      textField: 'name',
    } as IDropdownSettings;

    this.languagesSettings = {
      enableCheckAll: false,
      idField: 'languageId',
      textField: 'englishName',
    } as IDropdownSettings;

    this.locationsSettings = {
      enableCheckAll: false,
      idField: 'countryId',
      textField: 'name',
    } as IDropdownSettings;

    this.tagsSettings = {
      enableCheckAll: false,
      idField: 'tagId',
      textField: 'name',
    } as IDropdownSettings;
    this.route.paramMap.subscribe((params) => {
      if (params.get('id') != null) {
        this.badgeListingId = params.get('id') as string;
        this.isEditMode = true;
      }
    });

    combineLatest([
      this.appContext.user.pipe(
        mergeMap((user) =>
          this.masterDataService.getSolutionMasterData(user.languageId)
        )
      ),
      this.appContext.organization.pipe(
        filter(
          (organization): organization is UserOrganization => !!organization
        ),
        tap((organization) => {
          this.organizationName = organization.organizationName;
          this.organizationId = organization.organizationId;
        }),
        mergeMap(() => {
          return this.badgeListingId
            ? this.badgeService.getManageBadge(
              this.badgeListingId
            )
            : of(null);
        })
      ),
    ])
      .pipe(takeUntil(this._destroying$))
      .subscribe(([solutionMasterData, badge]) => {
        this.badgeListing =
          (badge?.result as CreateBadge | undefined) ??
          new CreateBadge();
        this.validFrom = this.stringToNgbDate(this.badgeListing.validFrom);
        this.validTo = this.stringToNgbDate(this.badgeListing.validTo);

        const masterdata = solutionMasterData;
        this.applyTypes = masterdata.applyTypes;
        this.termTypes = masterdata.termTypes;
        this.tags = masterdata.tags;
        this.publishTypes = masterdata.publishTypes;
        this.countries = masterdata.countries;
        this.languages = masterdata.languages;

        if (!this.isEditMode) {
          // Defaults
          if (this.publishTypes && this.publishTypes.length > 0)
            this.badgeListing.publishTypeId =
              this.publishTypes[0].publishTypeId;
        }
      });
  }
  onPrimaryImageSelect(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.primaryImageErrors = [];

      if (!this.allowedImageFileTypes.includes(event.target.files[0].type)) {
        this.primaryImageErrors.push(
          this.translate.instant('Common.SupportedImageFile')
        );
      }
      if (event.target.files[0].size > 2000000) {
        this.primaryImageErrors.push(
          this.translate.instant('Common.SupportedImageFileSize')
        );
      }

      if (this.primaryImageErrors.length > 0) {
        return;
      }

      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      var filename = event.target.files[0].name;
      var extension = filename.split('.').pop();
      reader.onload = (event) => {
        if (reader?.result !== null) {
          this.badgeListing.primaryImageUrl = reader?.result?.toString();
          this.badgeListing.primaryImageAsBase64 = new Base64File(
            reader?.result?.toString() ?? '',
            extension
          );
        }
      };
    }
  }
  isFormValid(): boolean {
    if(this.ngForm?.valid && this.isDateRangeValid && this.urlListValid){
      return true;
    };
    return false;
  }
  onSave() {
    this.isFormSubmitted = true;
    if (this.ngForm && this.ngForm.controls) {
      this.markAllControlsAsTouched();
    }

    if (this.isFormValid()) {
      if (!this.badgeListingId) {
        this.badgeService.addBadge(this.badgeListing).subscribe({
          next: (response) => {
            if ((response as ApiResponse).isSuccess) {
              this.notificationService.showSuccessToast(
                this.translate.instant('Common.Created')
              );
              window.location.href = this.BADGE_PATH;
            }
          },
          error: (error) => {
            if (!error.error.isSuccess) {
              if (error.status == '400') {
                error.error.errorCodes.forEach((errorCode: any) => {
                  this.vaidationErrors.push(
                    this.translate.instant('ErrorCodes.' + errorCode)
                  );
                });
              } else if (error.status == '404') {
                let errorMessage: string = '';
                errorMessage = this.translate.instant(
                  'ErrorCodes.' + error.error.errorCode
                );
                this.notificationService.showErrorToast(errorMessage);
              }
            }
          },
        });
      } else {
        this.badgeService
          .updateBadge(this.badgeListingId, this.badgeListing)
          .subscribe({
            next: (response) => {
              if (response.isSuccess) {
                this.notificationService.showSuccessToast(
                  this.translate.instant('Common.Saved')
                );
                window.location.href = this.BADGE_PATH;
              }
            },
            error: (error) => {
              if (!error.error.isSuccess) {
                if (error.status == '400') {
                  error.error.errorCodes.forEach((errorCode: any) => {
                    this.vaidationErrors.push(
                      this.translate.instant('ErrorCodes.' + errorCode)
                    );
                  });
                } else if (error.status == '404') {
                  let errorMessage: string = '';
                  errorMessage = this.translate.instant(
                    'ErrorCodes.' + error.error.errorCode
                  );
                  this.notificationService.showErrorToast(errorMessage);
                }
              }
            },
          });
      }
    }
  }

  private markAllControlsAsTouched(): void {
    const formControls = this.ngForm?.controls ?? {};
    Object.values(formControls).forEach((control) => {
      control.markAsTouched();
    });
  }

  onDelete() {
    this.badgeService.deleteBadge(this.badgeListing?.badgeId!).subscribe({
      next: (response) => {
        if (response.isSuccess) {
          this.notificationService.showSuccessToast(
            this.translate.instant('Common.Deleted')
          );
          window.location.href = this.BADGE_PATH;
        }
      },
      error: (error) => {
        if (!error.error.isSuccess) {
          if (error.status == '404') {
            let errorMessage: string = '';
            errorMessage = this.translate.instant(
              'ErrorCodes.' + error.error.errorCodes[0]
            );
            this.notificationService.showErrorToast(errorMessage);
          }
        }
      },
    });

    this.closeDialog();
  }

  showDeleteDialog() {
    this.deleteBadgeModal!.nativeElement.style.display = 'block';
  }

  closeDialog() {
    this.deleteBadgeModal!.nativeElement.style.display = 'none';
  }

  onDateRangeChange(newRange: { from: NgbDate | null; to: NgbDate | null; valid: boolean  }) {
    this.validFrom = newRange.from;
    this.validTo = newRange.to;
    this.badgeListing.validFrom = this.ngbDateToString(this.validFrom);
    this.badgeListing.validTo = this.ngbDateToString(this.validTo);
    this.isDateRangeValid = newRange.valid
  }

  onLinksChange(newValues: string[]) {
    this.badgeListing.links = newValues;
  }
  onUrlValidationStatus(isValid: boolean): void {
    this.urlListValid = isValid; 
  }
  ngbDateToString(ngbDate: NgbDate | null): string | undefined {
    if (!ngbDate) {
      return undefined;
    }

    const year = ngbDate.year;
    const month = ngbDate.month.toString().padStart(2, '0');
    const day = ngbDate.day.toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  stringToNgbDate(dateString: string | null | undefined): NgbDate | null {
    if (!dateString) {
      return null;
    }
    const date = new Date(dateString);
    return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }
  onImagesSelect(event: any) {
    if (event.target.files) {
      this.badgesImageErrors = [];
      const selectedFiles = Array.from(event.target.files) as File[];
  
      // Validate file count (selected + existing)
      if ((this.badgeListing.images?.length ?? 0) + selectedFiles.length > 3) {
        this.badgesImageErrors.push(
          this.translate.instant('Common.MaximumThreeImages')
        );
        return;
      }
  
      const validFiles: File[] = [];
      selectedFiles.forEach((file) => {
        // Validate file type
        if (!this.allowedImageFileTypes.includes(file.type)) {
          this.badgesImageErrors.push(
            this.translate.instant('Common.SupportedImageFile', {
              fileName: file.name,
            })
          );
          return;
        }
        // Validate file size
        if (file.size > 2000000) {
          this.badgesImageErrors.push(
            this.translate.instant('Common.SupportedImageFileSize', {
              fileName: file.name,
            })
          );
          return;
        }
        // If file passes validation, add to validFiles
        validFiles.push(file);
      });
  
      // If there are validation errors, stop processing
      if (this.badgesImageErrors.length > 0) {
        return;
      }
  
      // Initialize arrays if not already initialized
      if (!this.badgeListing.images) {
        this.badgeListing.images = [];
      }
      if (!this.badgeListing.imagesAsBase64) {
        this.badgeListing.imagesAsBase64 = [];
      }
  
      validFiles.forEach((file) => {
        const reader = new FileReader();
        const extension = file.name.split('.').pop() || 'unknown';
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (reader.result) {
            this.badgeListing.images!.push(reader.result.toString());
            this.badgeListing.imagesAsBase64!.push(
              new Base64File(reader.result.toString(), extension)
            );
          }
        };
      });
    }
  }
  removeImage(index: number): void {
    if (this.badgeListing.images && index >= 0 && index < this.badgeListing.images.length) {
      this.badgeListing.images.splice(index, 1); 
    }
  
    if (this.badgeListing.imagesAsBase64) {
      if (index >= 0 && index < this.badgeListing.imagesAsBase64.length) {
        this.badgeListing.imagesAsBase64.splice(index, 1);
      } else if (this.badgeListing.imagesAsBase64.length > 0) {
        this.badgeListing.imagesAsBase64.pop();
      }
    }
  }
  get normalizedLinks(): string[] {
    if (!this.badgeListing.links || this.badgeListing.links.length === 0) {
      this.badgeListing.links = [''];
    }
    return this.badgeListing.links;
  }
  
}
