import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  NgModule,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ToasterComponent } from './components/toaster/toaster.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import {
  NgbModule,
  NgbCollapseModule,
  NgbToastModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpConfigInterceptor } from './interceptors/http-config.interceptor';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpBackend,
} from '@angular/common/http';
import { BodyContainerComponent } from './components/layout/body-container/body-container.component';
import { BodyTopbarComponent } from './components/layout/body-topbar/body-topbar.component';
import { SidebarComponent } from './components/layout/sidebar/sidebar.component';
import { MenuCategoryComponent } from './components/layout/menu-category/menu-category.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { UrlListComponent } from './components/url-list/url-list.component';
import { ActiveStatusComponent } from './components/active-status/active-status.component';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { AppRoutingModule } from '../app-routing.module';
import { GlobalErrorHandlerService } from './global-error/global-error-handler.service';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  ProtectedResourceScopes,
} from '@azure/msal-angular';
import {
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import {
  Endpoints,
  getMsalConfig,
  getProtectedResources,
} from './auth-config/auth-config';
import { NumericInputDirective } from './directives/numeric-input.directive';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { NoAssignedOrganizationComponent } from './components/no-assigned-organization/no-assigned-organization.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { MissingPermissionsPropertiesComponent } from './components/missing-permissions-properties/missing-permissions-properties.component';
import { HomeComponent } from './components/home/home.component';
import { ContactComponent } from './components/contact/contact.component';
import { AppConfigService } from './services/app.config.service';
import { AppConfig } from './models/AppConfig';
import { ReactiveFormsModule } from '@angular/forms';
import { CheckboxFilterDrawerComponent } from './components/checkbox-filter-drawer/checkbox-filter-drawer.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { UrlValidatorDirective } from './directives/url-validator-directive';
import { FindSolutionsLegacyComponent } from './components/find-solutions/find-solutions.component';

function appConfigInit(appConfigService: AppConfigService) {
  return () => {
    return appConfigService.init();
  };
}

function translateInitializerFactory(translate: TranslateService) {
  return () => {
    translate.setDefaultLang('en');
    return translate.use('en').toPromise();
  };
}

function translateHttpLoaderFactory(httpBackend: HttpBackend) {
  return new TranslateHttpLoader(new HttpClient(httpBackend));
}

function getProtectedResourceMap(appConfig: AppConfig) {
  const protectedResourceMap = new Map<
    string,
    Array<string | ProtectedResourceScopes> | null
  >();
  const protectedResources = getProtectedResources(appConfig);

  for (const endpointKey in protectedResources.endpoints) {
    const endpoint =
      protectedResources.endpoints[endpointKey as keyof Endpoints];
    const methods = endpoint.httpMethods.map((method: any) => {
      return {
        httpMethod: method,
        scopes: protectedResources.scopes.apiAccess,
      };
    });
    protectedResourceMap.set(endpoint.url, methods);
  }
  return protectedResourceMap;
}

function msalInstanceFactory(
  appConfigService: AppConfigService
): IPublicClientApplication {
  return new PublicClientApplication(getMsalConfig(appConfigService.appConfig));
}

function msalGuardConfigFactory(
  appConfigService: AppConfigService
): MsalGuardConfiguration {
  const protectedResources = getProtectedResources(appConfigService.appConfig);

  return {
    // The routing guard configuration.
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: protectedResources.scopes.apiAccess,
    },
  };
}

function msalInterceptorConfigFactory(
  appConfigService: AppConfigService
): MsalInterceptorConfiguration {
  return {
    // MSAL interceptor configuration.
    // The protected resource mapping maps your web API with the corresponding app scopes. If your code needs to call another web API, add the URI mapping here.
    interactionType: InteractionType.Redirect,
    protectedResourceMap: getProtectedResourceMap(appConfigService.appConfig),
  };
}

@NgModule({
  declarations: [
    SidebarComponent,
    MenuCategoryComponent,
    BodyContainerComponent,
    BodyTopbarComponent,
    ToasterComponent,
    NumericInputDirective,
    ConfirmModalComponent,
    NoAssignedOrganizationComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    MissingPermissionsPropertiesComponent,
    HomeComponent,
    ContactComponent,
    DateRangeComponent,
    UrlListComponent,
    ActiveStatusComponent,
    CheckboxFilterDrawerComponent,
    UrlValidatorDirective,
    FindSolutionsLegacyComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    AppRoutingModule,
    NgxSpinnerModule.forRoot({ type: 'square-loader' }),
    TranslateModule.forRoot({
      /*defaultLanguage: 'en',*/
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
    NgbToastModule,
    InfiniteScrollModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbModule,
    ReactiveFormsModule,
  ],
  exports: [
    CommonModule,
    NgxSpinnerModule,
    AppRoutingModule,
    TranslateModule,
    NgbToastModule,
    SidebarComponent,
    MenuCategoryComponent,
    BodyContainerComponent,
    BodyTopbarComponent,
    ToasterComponent,
    NumericInputDirective,
    ConfirmModalComponent,
    NoAssignedOrganizationComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    MissingPermissionsPropertiesComponent,
    DateRangeComponent,
    UrlListComponent,
    ActiveStatusComponent,
    CheckboxFilterDrawerComponent,
    UrlValidatorDirective,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appConfigInit,
      multi: true,
      deps: [AppConfigService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: translateInitializerFactory,
      multi: true,
      deps: [TranslateService],
    },
    // { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: msalInstanceFactory,
      deps: [AppConfigService],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: msalGuardConfigFactory,
      deps: [AppConfigService],
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: msalInterceptorConfigFactory,
      deps: [AppConfigService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalService,
    MsalBroadcastService,
    MsalGuard,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
