<div class="body flex-grow-1 px-3">
  <div class="container-lg mb-4">
    <div class="row border-bottom">
      <div class="d-flex justify-content-between align-items-center pt-07">
        <h5 class="card-title mb-0 fw-semibold" translate>
          <img
            [src]="'/assets/icons/profile-icn.svg'"
            [alt]="'UserProfile.UserProfile' | translate"
            class="pr-01 page-title-icon"
          />
          UserProfile.UserProfile
        </h5>
      </div>
      <p translate class="pt-07">UserProfile.UserProfileHelpText</p>
      <!-- <div class="d-flex justify-content-between align-items-center pb-03">
        <h5 class="card-title mb-0 fw-semibold"></h5>
        <button
          class="btn btn-primary"
          type="button"
          (click)="addOrganization()"
          translate
        >
          UserProfile.AddOrganization
        </button>
      </div> -->
    </div>
    <div class="pt-4">
      <form
        (ngSubmit)="onSubmit()"
        [formGroup]="userProfileForm"
        *ngIf="userProfile"
      >
        <div class="border-bottom pb-4 mb-3">
          <div class="row">
            <div class="mb-3 row">
              <label
                for="firstName"
                class="col-sm-12 col-md-4 col-lg-2 col-form-label fw-semibold"
                translate
                >UserProfile.FirstName<span class="text-danger required-field"
                  >*</span
                ></label
              >
              <div class="col-sm-12 col-md-7 col-lg-4">
                <input
                  type="text"
                  id="firstName"
                  class="form-control"
                  formControlName="firstName"
                  maxlength="30"
                  name="firstName"
                  [ngClass]="
                    firstName?.invalid &&
                    (firstName?.dirty || firstName?.touched)
                      ? 'invalid-input'
                      : ''
                  "
                  required
                />
                <span
                  class="text-danger"
                  *ngIf="
                    firstName?.invalid &&
                    firstName?.touched &&
                    firstName?.value == ''
                  "
                >
                  {{ "UserProfile.FirstNameRequired" | translate }}
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mb-3 row">
              <label
                for="lastName"
                class="col-sm-12 col-md-4 col-lg-2 col-form-label fw-semibold"
                translate
                >UserProfile.LastName<span class="text-danger required-field"
                  >*</span
                ></label
              >
              <div class="col-sm-12 col-md-7 col-lg-4">
                <input
                  type="text"
                  id="lastName"
                  class="form-control"
                  formControlName="lastName"
                  maxlength="30"
                  name="lastName"
                  [ngClass]="
                    lastName?.invalid && (lastName?.dirty || lastName?.touched)
                      ? 'invalid-input'
                      : ''
                  "
                  required
                />
                <span
                  class="text-danger"
                  *ngIf="
                    lastName?.invalid &&
                    lastName?.touched &&
                    lastName?.value == ''
                  "
                >
                  {{ "UserProfile.LastNameRequired" | translate }}
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mb-3 row">
              <label
                for="email"
                class="col-sm-12 col-md-4 col-lg-2 col-form-label fw-semibold"
                translate
                >UserProfile.Email</label
              >
              <div class="col-sm-12 col-md-7 col-lg-4">
                {{ userProfile.user.email }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mb-3 row">
              <label
                for="country"
                class="col-sm-12 col-md-4 col-lg-2 col-form-label fw-semibold"
                translate
                >UserProfile.Country<span class="text-danger required-field"
                  >*</span
                ></label
              >
              <div class="col-sm-12 col-md-7 col-lg-3">
                <select
                  class="form-select"
                  formControlName="countryId"
                  name="country"
                  id="country"
                  [ngClass]="
                    countryId?.invalid &&
                    (countryId?.dirty || countryId?.touched)
                      ? 'invalid-input'
                      : ''
                  "
                >
                  <option [ngValue]="undefined">
                    {{ "UserProfile.SelectCountry" | translate }}
                  </option>
                  <option
                    *ngFor="
                      let country of userProfile.countries;
                      trackBy: trackByCountry
                    "
                    [value]="country.countryId"
                  >
                    {{ country.name }}
                  </option>
                </select>
                <span
                  class="text-danger"
                  *ngIf="
                    countryId?.invalid &&
                    countryId?.touched &&
                    countryId?.value == undefined
                  "
                >
                  {{ "UserProfile.CountryRequired" | translate }}
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mb-3 row">
              <label
                for="language"
                class="col-sm-12 col-md-4 col-lg-2 col-form-label fw-semibold"
                translate
                >UserProfile.Language<span class="text-danger required-field"
                  >*</span
                ></label
              >
              <div class="col-sm-12 col-md-7 col-lg-3">
                <select
                  class="form-select"
                  formControlName="languageId"
                  name="language"
                  id="language"
                  [ngClass]="
                    languageId?.invalid &&
                    (languageId?.dirty || languageId?.touched)
                      ? 'invalid-input'
                      : ''
                  "
                >
                  <option [ngValue]="undefined">
                    {{ "UserProfile.SelectLanguage" | translate }}
                  </option>
                  <option
                    *ngFor="
                      let language of userProfile.languages;
                      trackBy: trackByLanguage
                    "
                    [value]="language.languageId"
                  >
                    {{ language.name }}
                  </option>
                </select>
                <span
                  class="text-danger"
                  *ngIf="
                    languageId?.invalid &&
                    languageId?.touched &&
                    languageId?.value == undefined
                  "
                >
                  {{ "UserProfile.LanguageRequired" | translate }}
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mb-3 row">
              <label
                for="gender"
                class="col-sm-12 col-md-4 col-lg-2 col-form-label fw-semibold"
                translate
                >UserProfile.Gender<span class="text-danger required-field"
                  >*</span
                ></label
              >
              <div class="col-sm-12 col-md-7 col-lg-3">
                <select
                  class="form-select"
                  formControlName="genderId"
                  name="gender"
                  id="gender"
                  [ngClass]="
                    genderId?.invalid && (genderId?.dirty || genderId?.touched)
                      ? 'invalid-input'
                      : ''
                  "
                >
                  <option [ngValue]="undefined">
                    {{ "UserProfile.SelectGender" | translate }}
                  </option>
                  <option
                    *ngFor="
                      let gender of userProfile.genders;
                      trackBy: trackByGender
                    "
                    [value]="gender.genderId"
                  >
                    {{ gender.name }}
                  </option>
                </select>
                <span
                  class="text-danger"
                  *ngIf="
                    genderId?.invalid &&
                    genderId?.touched &&
                    genderId?.value == undefined
                  "
                >
                  {{ "UserProfile.GenderRequired" | translate }}
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mb-3 row">
              <label
                for="mvaNewsletter"
                class="col-sm-12 col-md-4 col-lg-2 col-form-label fw-semibold"
                translate
                >UserProfile.MVANewsletter</label
              >
              <div class="col-sm-12 col-md-7 col-lg-4">
                <input
                  type="checkbox"
                  id="mvaNewsletter"
                  class="form-check-input"
                  formControlName="mvaNewsletter"
                  name="mvaNewsletter"
                />
              </div>
            </div>
          </div>

          <!-- Badges -->
          <div class="row" *ngIf="appContext.enableSolutions$ | async">
            <label
              class="col-sm-12 col-md-4 col-lg-2 col-form-label fw-semibold"
              translate
              >UserProfile.Badges</label
            >
            <div class="col-sm-12 col-md-7 col-lg-4 d-flex gap-3">
              <div
                *ngFor="let badge of userProfile.user.badges"
                class="badge-container"
              >
                <img [src]="badge.primaryImageUrl" alt="" />
                <button type="button" (click)="onDeleteBadge(badge.badgeId)">
                  -
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- User Organizations -->
        <div
          class="table-responsive"
          *ngIf="userProfile.user.userOrganizations.length != 0"
        >
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="col-md-10">
              <h6 class="fw-semibold" translate><img
                [src]="'/assets/icons/org-icon.svg'"
                [alt]="'UserProfile.UserOrganizationTitle' | translate"
                class="pr-01 page-title-icon"
              /> UserProfile.UserOrganizationTitle</h6>
              <p translate>
                 UserProfile.UserOrganizationVisibility
              </p>
          </div>
          <div>
            <button
              class="btn btn-primary"
              type="button"
              (click)="addOrganization()"
              translate
            >
              UserProfile.AddOrganization
            </button>
          </div>
        </div>

          <table class="table align-middle">
            <thead class="table-light">
              <tr>
                <th scope="col" class="w-18" translate>
                  UserProfile.Organization
                </th>
                <th scope="col" class="w-18" translate>UserProfile.Role</th>
                <th translate *ngIf="appContext.enableSolutions$ | async">UserProfile.Visibility</th>
                <th scope="col" class="w-16" translate>UserProfile.Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let userOrg of userProfile.user.userOrganizations;
                  let i = index;
                  trackBy: trackByUserOrganizations
                "
                formArrayName="orgVisibility"
              >
                <td>{{ userOrg.organizationName }}</td>
                <td>{{ userOrg.roleName }}</td>
                <td *ngIf="appContext.enableSolutions$ | async">
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      [formControlName]="i"
                    />
                    <label class="form-check-label">
                      {{
                        (orgVisibility.at(i).value
                          ? "Visibility.Visible"
                          : "Visibility.NotVisible"
                        ) | translate
                      }}
                    </label>
                  </div>
                </td>

                <td class="mx-auto">
                  <img
                    src="../../../assets/icons/remove-org.svg"
                    (click)="removeOrganizationModal(userOrg)"
                    class="btn-icon"
                    title="remove"
                    type="button"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <button
          class="btn btn-secondary mx-2 mb-3"
          (click)="onDeleteDialog()"
          type="button"
          translate
        >
          UserProfile.Delete
        </button>
        <button
          class="btn btn-primary mx-2 mb-3"
          (click)="onChangePassword()"
          type="button"
          translate
        >
          UserProfile.ChangePassword
        </button>
        <button
          class="btn btn-primary mx-2 mb-3"
          type="submit"
          [disabled]="!userProfileForm.dirty"
          translate
        >
          UserProfile.Save
        </button>
      </form>
    </div>
  </div>
</div>
