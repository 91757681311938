import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subject, filter, mergeMap, takeUntil } from 'rxjs';
import { BadgeService } from 'src/app/services/badge.service';
import { RoutePath } from 'src/app/shared/app-constants/constants';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { AppContext } from 'src/app/shared/context/AppContext';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { CreateBadge } from 'src/app/shared/models/CreateBadge';
import { PaginatedRequest } from 'src/app/shared/models/PaginatedRequest';
import { PaginatedResponse } from 'src/app/shared/models/PaginatedResponse';
import { User } from 'src/app/shared/models/User';
import { UserOrganization } from 'src/app/shared/models/UserOrganization';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-manage-badges-list',
  templateUrl: './manage-badges-list.component.html',
  styleUrls: ['./manage-badges-list.component.css']
})
export class ManageBadgesListComponent {
  private readonly _destroying$ = new Subject<void>();
  organizationName?: string;
  badges: CreateBadge[] = [];
  orgId?: string;
  user!: User;
  activeTab: number = 1;
  pageNumber: number = 1;
  pageSize: number = 15;
  totalPages = Infinity
  readonly BADGE_PATH = `/${RoutePath.Manage}/${RoutePath.Badges}`;
  constructor(
    private router: Router,
    private appContext: AppContext,
    private badgeService: BadgeService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private translate: TranslateService,
    private notificationService: NotificationService
  ) { }
  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  ngOnInit(): void {
    this.appContext.organization
      .pipe(
        filter(
          (organization): organization is UserOrganization => !!organization
        ),
        mergeMap((organization) => {
          this.organizationName = organization.organizationName;
          return this.badgeService.getOrganisationBadgeListings(
            { pageNumber: this.pageNumber, pageSize: this.pageSize }
          );
        }),
        takeUntil(this._destroying$)
      )
      .subscribe((response) => {
        this.badges = response.result.results;
      });
  }

  listData(request: PaginatedRequest<any>) {
    this.badgeService.getOrganisationBadgeListings
      (request)
      .subscribe((response) => {
        console.log(response.result.results)
        this.totalPages = response.result.totalPages
        this.badges = [...this.badges, ...response.result.results]
        console.log(this.badges)
      });
  }
  listUserData(request: PaginatedRequest<any>) {
    this.badgeService.getUserOrganisationBadgeListings
      (request)
      .subscribe((response) => {
        this.totalPages = response.result.totalPages
        this.badges = [...this.badges, ...response.result.results]
      });
  }
  onScroll(): void {
    const nextPage = this.pageNumber + 1;
    if (nextPage > this.totalPages) {
      return;
    }
    this.pageNumber = nextPage;
    this.loadData(this.pageNumber, this.pageSize);
  }

  onTabChange(activeTab: number): void {
    this.activeTab = activeTab;
    this.pageNumber = 1;
    this.badges = [];
    this.loadData(this.pageNumber, this.pageSize);
  }

  private loadData(pageNumber: number, pageSize: number): void {
    const params = { pageNumber, pageSize };

    if (this.activeTab === 1) {
      this.listData(params);
    } else {
      this.listUserData(params);
    }
  }
  deleteBadge(badgeId: string) {
    this.showConfirmModal(
      this.translate.instant('Badge.Confirm'),
      this.translate.instant('Badge.DeleteWarning')
    ).then(() => {
      this.badgeService
        .deleteBadge(badgeId)
        .subscribe(() => {
          this.notificationService.showSuccessToast(
            this.translate.instant('Common.Deleted')
          );
          this.onTabChange(1)
        });
    });
  }

  addBadge() {
    this.router.navigate([`${this.BADGE_PATH}/add`])
  }
  showConfirmModal(title: string, message: string) {
    return new Promise<void>((resolve) => {
      this.modalService.dismissAll();
      const modalRef = this.modalService.open(ConfirmModalComponent, {
        centered: true,
      });
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.bodyMessage = message;
      modalRef.componentInstance.yesText =
        this.translate.instant('Common.YesDelete');
      modalRef.componentInstance.noText =
        this.translate.instant('Common.NoCancel');
      modalRef.componentInstance.yesAction.subscribe((text: any) => {
        this.modalService.dismissAll();
        resolve();
      });
    });
  }
}
