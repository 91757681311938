
<ng-template #filterDrawer let-offcanvas>
  <form name="createBranForm">
    <div class="offcanvas offcanvas-end show filter-drawer">
      <div class="offcanvas-header border-bottom">
        <h6 class="offcanvas-title" id="offcanvas-basic-title">
          {{ "FindMedia.Filters" | translate }} ({{ totalFilteredOrganizations }})
        </h6>
        <div
          type="button"
          class="btn-off"
          data-coreui-dismiss="offcanvas"
          aria-label="Close"
          (click)="closeFilterDrawer()"
        ></div>
      </div>

      <div class="offcanvas-body">

        <div ngbAccordion>
          <!-- Organization Type-->
          <div ngbAccordionItem [collapsed]="false" class="checkbox-panel">
            <!-- Accordion Header -->
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton>
                {{ "FindMedia.SavedViews" | translate }}
              </button>
            </h2>
        
            <!-- Accordion Body -->
            <div ngbAccordionCollapse>
              <div
              *ngIf="SavedView.length == 0"
              class="filter-options my-1 p-1"
              style="background-color: #f2f2f2"
            >
              <div class="mt-1 mb-3">
                <p class="text-center fw-bold my-2" translate>
                  FindMedia.NoSavedViews
                </p>
                <p class="text-center mb-3" translate>
                  FindMedia.NoSavedViewEmptyState
                </p>
              </div>
            </div>
            <div
              class="d-flex align-items-center mb-3 ms-2"
              *ngIf="SavedView.length > 0"
            >
             

              <select
                class="form-select"
                id="savedViewsSelect"
                [(ngModel)]="selectedViewId"
                name="select"
                (change)="onSelectSavedView($event)"
              >
              <option [selected]="!selectedViewId" 
              value="">{{ emptyOptionText | translate }}
             </option>
                <option *ngFor="let view of SavedView"
                 [value]="view.id"
                 [selected]="view.id === selectedViewId"
                 >
                  {{ view.name }}
                  <ng-container *ngIf="view.isDefault">
                    <em>{{ "FindMedia.Default" | translate }}</em>
                  </ng-container>
                </option>
              </select>

             
              <div ngbDropdown>
                <button
                  dropdown-toggle
                  btn
                  p-0
                  class="btn p-0 custom-dropdown-toggle"
                  ngbDropdownToggle
                  aria-expanded="false"
                  *ngIf="selectedViewId"
                >
                  <i class="bi bi-three-dots ms-1"></i>
                </button>
                <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end p-0 custom-shadow">
                    <button *ngIf="!this.singleSaveView?.isDefault"
                      ngbDropdownItem
                      class="dropdown-item side-action"
                      (click)="showConfirmModal('default')"
                      translate
                    >
                      <i class="bi bi-bookmark-check me-2"></i> FindMedia.MakeMyDefault
                    </button>
                    <div class="dropdown-divider m-0"></div>
                    <button
                      ngbDropdownItem
                      class="dropdown-item side-action"
                      (click)="showSaveToViewModal('rename')"
                      translate
                    >
                      <i class="bi bi-pencil-square me-2"></i> FindMedia.Rename
                    </button>
                    <div class="dropdown-divider m-0"></div>
                    <button
                      ngbDropdownItem
                      class="dropdown-item text-danger side-action"
                      (click)="showConfirmModal('delete')"
                      translate
                    >
                      <i class="bi bi-trash me-2"></i> FindMedia.Delete
                    </button>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>

        <!-- Accordion -->
        <div ngbAccordion>
          <!-- Organization Type-->
          <div ngbAccordionItem [collapsed]="true" class="checkbox-panel">
            <!-- Accordion Header -->
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton>
                {{ "FindMedia.OrganizationTypeTitle" | translate }}  ({{ getOrganizationTypeCount() }})
              </button>
            </h2>

            <!-- Accordion Body -->
            <div ngbAccordionCollapse>
              <div ngbAccordionBody class="checkbox-container">
                <div>
                  <div class="form-check" *ngFor="let orgType of organizationTypes let i = index">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      [id]="'orgType' + i"
                      [value]="orgType.organizationTypeId"
                      [checked]="
                        selectedOrganizationTypes.includes(
                          orgType.organizationTypeId
                        )
                      "
                      name="organizationType{{ i }}"
                      (change)="
                        onCheckboxChange($event, selectedOrganizationTypes)
                      "
                    />
                    <label class="form-check-label" [for]="'orgType' + i">
                      {{ orgType.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div ngbAccordion>
          <!-- Properties-->
          <div ngbAccordionItem [collapsed]="true" class="checkbox-panel">
            <!-- Accordion Header -->
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton>
                {{"FindMedia.PropertiesTitle" | translate}} ({{ getPropertyTypeCount() }})
              </button>
            </h2>

            <!-- Accordion Body -->
            <div ngbAccordionCollapse>
              <div ngbAccordionBody class="checkbox-container">
                <div>
                  <div class="form-check" *ngFor="let property of propertySubTypes let i = index">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      [id]="'property' + i"
                      [value]="property.propertySubtypeId"
                      [checked]="
                        selectedPropertySubTypes.includes(
                          property.propertySubtypeId
                        )
                      "
                      name="propertySubType{{ i }}"
                      (change)="
                        onCheckboxChange($event, selectedPropertySubTypes)
                      "
                    />
                    <label class="form-check-label" [for]="'property' + i">
                      {{ property.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ngbAccordion>
          <!-- No of Employee-->
          <div ngbAccordionItem [collapsed]="true" class="checkbox-panel">
            <!-- Accordion Header -->
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton>
                {{"FindMedia.NoOfEmployeeTitle" | translate}} ({{ getNoOfEmployeeCount() }})
              </button>
            </h2>

            <!-- Accordion Body -->
            <div ngbAccordionCollapse>
              <div ngbAccordionBody class="checkbox-container">
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      [value]="100"
                      [checked]="selectedNoOfEmployee.includes('100')"
                      name="noOfEmployee100"
                      (change)="onCheckboxChange($event, selectedNoOfEmployee)"
                    />
                    <label class="form-check-label">
                      <100
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      [value]="100-499"
                      [checked]="selectedNoOfEmployee.includes('100-499')"
                      name="noOfEmployee499"
                      (change)="onCheckboxChange($event, selectedNoOfEmployee)"
                    />
                    <label class="form-check-label">
                      100-499
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      [value]="500"
                      [checked]="selectedNoOfEmployee.includes('500')"
                      name="noOfEmployee500"
                      (change)="onCheckboxChange($event, selectedNoOfEmployee)"
                    />
                    <label class="form-check-label">
                      >500
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ngbAccordion>
          <!-- Properties-->
          <div ngbAccordionItem [collapsed]="true" class="checkbox-panel">
            <!-- Accordion Header -->
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton>
                {{"FindMedia.OrganizationAgeTitle" | translate}} ({{ getOrganizationAgeCount() }})
              </button>
            </h2>

            <!-- Accordion Body -->
            <div ngbAccordionCollapse>
              <div ngbAccordionBody class="checkbox-container">
                <div>
                  <div class="form-check" *ngFor="let age of organizationAges; let i = index">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      [id]="'age' + i"
                      [checked]="selectedOrganizationAges.includes(age.name)"
                      name="organizationAge{{ i }}"
                      (change)="
                        onCheckboxChange($event, selectedOrganizationAges)
                      "
                    />
                    <label class="form-check-label" [for]="'agr' + i">
                      {{ age.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="position-absolute bottom-0 start-50 translate-middle-x w-100 justify-content-center align-items-center d-flex"
        style="height: 64px; border-top: 1px solid #e2e2e2; background-color: white;"
      >
        <button class="btn btn-reset" translate (click)="applyFilter()">
          FindMedia.ResetFilters
        </button>
        <button
            class="btn btn-primary btn-filter-custom"
            (click)="showCreateViewModal()"
            [disabled]="isFilterParamsEmpty"
            translate
          >
            FindMedia.SaveToMyViews
          </button>
      </div>
    </div>
  </form>
</ng-template>
