<div class="table-responsive mt-4" *ngIf="properties && properties.length > 0">
  <table class="table">
    <thead>
      <tr>
        <th style="width: 12%;"></th>
        <th translate>FindMedia.NameHeader</th>
        <th translate>FindMedia.Location</th>
        <th translate>FindMedia.Languages</th>
        <th style="width: 12%;" translate>FindMedia.TotalBadges</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr class="custom-row" *ngFor="let property of properties">
        <td align="center">
          <a
            [href]="property.propertyUrl"
            style="display: inline-flex; align-items: center"
          >
            <img 
            [src]="getIcon(property.propertySubTypeId)" alt="{{property.name}}"
            class="me-3 mt-1 d-none d-md-inline"
              style="width: 38px;flex-shrink: 0;" />
          </a>
        </td>
        <td>
          <span  style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; flex-grow: 1;
          padding-left: 4px;margin-top:30px">{{
           property.name
         }}</span>
        </td>
        <td>
          <p class="table-spacing">
            {{ property.location }}
          </p>
        </td>
        <td>
          <p class="table-spacing">{{ property.language }}</p>
        </td>
        <td>
          <div class="badge-custom ms-4 image-container">
            <img
              src="../../../../assets/img/tempbadge.png"
              alt="FindMedia.TotalBadges"
            />
            <div class="centered-text" *ngIf="property.totalBadges >= 1000">
              &#8805;{{ property.totalBadges }}
            </div>
            <div class="centered-text" *ngIf="property.totalBadges < 1000">
              {{ property.totalBadges }}
            </div>
          </div>
        </td>
        <td class="text-end py-4 pe-5">
          <button
            (click)="copyToClipboard(property); $event.stopPropagation()"
            class="btn btn-default icon-link px-2"
          >
            <img src="../../../../assets/img/copyurl.png" />
          </button>
          <a
            class="favorite-icon"
            (click)="
              $event.stopPropagation(); onFavourite(proppopover, property)
            "
            aria-label="Toggle Favorite"
          >
            <img
              [src]="
                property.isFavorite
                  ? '../../../../assets/img/activefav.png'
                  : '../../../../assets/img/fav.png'
              "
              (mouseleave)="proppopover.close()"
              [ngbPopover]="propPopover"
              #proppopover="ngbPopover"
              placement="bottom-right"
              container="body"
              triggers="manual"
              width="20"
              height="20"
              alt="FindMedia.Favorites"
            />
          </a>
          <ng-template #propPopover>
            {{
              property.isFavorite
                ? ("Common.AddedToFavourites" | translate)
                : ("Common.RemovedFromFavourites" | translate)
            }}
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="row text-center m-5" *ngIf="totalRecords === 0">
    <h3 class="empty-state" translate>FindMedia.NoMatchingRecordsTitle</h3>
    <p translate>FindMedia.NoMatchingRecords</p>
  </div>
</div>
