import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, mergeMap, takeUntil, map, Observable } from 'rxjs';
import { User } from 'src/app/shared/models/User';
import { ActivatedRoute, Router } from '@angular/router';
import { SolutionListingService } from 'src/app/services/solution.listings.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SolutionListing } from 'src/app/shared/models/SolutionListing';
import { PaginatedResponse } from 'src/app/shared/models/PaginatedResponse';

@Component({
  selector: 'app-solutions-list',
  templateUrl: './solutions-list.component.html',
  styleUrls: ['./solutions-list.component.css'],
})
export class ManageSolutionsListComponent implements OnInit, OnDestroy {
  organizationName?: string;
  solutions: SolutionListing[] = [];
  orgId?: string;
  user!: User;

  currentPage = 1;
  totalPages = Infinity;
  pageSize = 15;
  totalItemCount: number | null = null;

  type = 'me';

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private solutionListingService: SolutionListingService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        map(({ type }) => type),
        mergeMap((type) => {
          this.type = type;
          this.currentPage = 1;

          return this.fetchSolutions();
        }),
        takeUntil(this._destroying$)
      )
      .subscribe((res) => {
        this.totalPages = res.totalPages;
        this.totalItemCount = res.totalItems;
        this.solutions = res.results;
      });
  }

  deleteSolution(solutionListingId: string) {
    const confirmMessage = this.translate.instant('Solution.Confirm');
    const deteleWarning = this.translate.instant('Solution.DeleteWarning');

    const confirm$ = this.showConfirmModal(confirmMessage, deteleWarning);

    confirm$
      .pipe(
        mergeMap(() =>
          this.solutionListingService.deleteSolutionListing(solutionListingId)
        )
      )
      .subscribe(() => {
        this.solutions = this.solutions?.filter(
          (s) => s.solutionListingId !== solutionListingId
        );

        this.notificationService.showSuccessToast(
          this.translate.instant('Common.Deleted')
        );
      });
  }

  fetchSolutions(): Observable<PaginatedResponse<SolutionListing>> {
    if (this.type === 'me') {
      return this.solutionListingService.getMySolutionListings({
        pageSize: this.pageSize,
        pageNumber: this.currentPage,
      });
    }

    if (this.type !== 'org') {
      this.router.navigate([], {
        queryParams: { type: 'org' },
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
    }

    return this.solutionListingService.getOrganisationSolutionListings({
      pageSize: this.pageSize,
      pageNumber: this.currentPage,
    });
  }

  showConfirmModal(title: string, message: string): Observable<void> {
    this.modalService.dismissAll();

    const modalRef = this.modalService.open(ConfirmModalComponent, {
      centered: true,
    });

    modalRef.componentInstance.title = title;
    modalRef.componentInstance.bodyMessage = message;
    modalRef.componentInstance.yesText =
      this.translate.instant('Common.YesDelete');
    modalRef.componentInstance.noText =
      this.translate.instant('Common.NoCancel');

    return modalRef.componentInstance.yesAction.pipe(
      map(() => {
        this.modalService.dismissAll();
      })
    );
  }

  onScroll() {
    const nextPage = this.currentPage + 1;
    if (nextPage > this.totalPages) {
      return;
    }

    this.currentPage = nextPage;
    const solutions$ = this.fetchSolutions();

    solutions$.subscribe((res) => {
      this.solutions = [...this.solutions, ...res.results];
    });
  }
}
